define('addressSearchVM',['durandal/app', 'knockout', 'advanceAddressSearch', 'services/logger', 'apnSearch', 'ownerSearch', 'streetSearch', 'propertySearchModel', 'Q', 'plugins/http', 'config', 'metisMenu', 'jquery.slimscroll', 'global/session', 'plugins/router', 'alertNotification', 'commonDialogModel', 'koPaging', 'utlities', 'propertyDetailService', 'propertyDetailResponse', 'propertyProfileModel', 'propertyProfileService', 'accounting', 'nameValue', 'paymentProcessModel', 'propertySearchCommon', 'addressSearchService', 'header', 'radiusSearch', 'moment', 'ftniForm', 'pendingApprovalService'],
    function (app, ko, addressSearch, logger, apnSearch, ownerSearch, streetSearch, model, Q, https, config, metisMenu, slimscroll, session, router, alert, commonDialog, koPaging, utlities, propertyDetailService, propertyDetailResponse, propertyProfile, propertyProfileService, accounting, nameValue, paymentProcessModel, propertySearchCommon, addressSearchService, header, radiusSearch, moment, ftniForm, pendingApprovalService) {
        var savedCart = ko.observable(undefined);
        var searchType = ko.observable('Address');
        var APNSearchAPNNumber = ko.observable('');
        var APNSearchCounty = ko.observable('');
        var showProfileLinks = ko.observable(false);
        var isHighContrastOn = ko.observable(false).extend({
            notify: 'always'
        });
        var ftniFormModel = new ftniForm('ftniForm');
        var isAdvanceSearchEnabled = ko.observable(false);
        var showAdvanceSearchBadge = ko.observable(true);
        var isQuickSearchNoResultError = ko.observable(false);
        var isBasicSearchNoResult = ko.observable(true).extend({
            notify: 'always'
        });
        var isFindYourPropertyEnabled = ko.observable(true);
        var isAdvanceSearchFromAddressSearch = ko.observable(true);
        var showRadiusSearchSlideBar = ko.observable(false);
        var isMinimizeAdvanceSearch = ko.observable(false);
        var searchResult = ko.observable(undefined);
        var APNsearchResult = ko.observable(undefined);
        var isLatLongSearchRequest = ko.observable(false);
        var latLongSearchLocation = ko.observable(undefined);
        var currentTargetLocation = ko.observable(undefined);
        var apnModel = new model.ApnSearch();
        var ownerModel = new model.OwnerSerach();
        var isBingAvailable = ko.observable(true);
        var isSearchByClick = ko.observable(false);
        var isParcelOn = ko.observable(false);
        var isFindPropertyOn = ko.observable(false);
        var toggleParcelTitle = ko.observable(' Show Parcel');
        var toggleFindPropertyTitle = ko.observable(' Show Find Property');
        var addressModel;
        if (session.userAddressZip() != undefined && session.userAddressZip() != "" && session.userAddressZip() != null) {
            addressModel = new model.AddressSerach(session.userAddressStateAbbr(), session.userAddressCity(), session.userAddressZip());
        }
        else {
            addressModel = new model.AddressSerach();
        }
        var streetLookupModel = new model.StreetLookUpSearch();
        var customCompSettingsModel = new model.CustomCompSettings();
        var profileLinks = ko.observableArray();
        var stateList = ko.observableArray();
        var ownerCountyList = ko.observableArray();
        var apnCountyList = ko.observableArray();
        var streetCountyList = ko.observableArray();
        var ownerfips = ko.observableArray();
        var streetfips = ko.observableArray();
        var apnfips = ko.observableArray();
        var selectedPropertylatitude = ko.observable();
        var selectedPropertylongitude = ko.observable();
        var ownerfipsNo = ko.observable('');
        var streetfipsNo = ko.observable('');
        var apnfipsNo = ko.observable('');
        var isPredictiveSearch = ko.observable(false);
        var isLatLongAvailable = ko.observable(false).extend({
            notify: 'always'
        });
        var propertyListing = ko.observableArray();
        propertyListing.subscribe(function (value) {
            if (value != null && value.length > 1) {
                $('#propOwnerHd').trigger("focus");
            }
        });
        var isStreetView = ko.observable(false).extend({
            notify: 'always'
        });

        var isFullScreen = ko.observable(false).extend({
            notify: 'always'
        });

        var toggleSplitScreenTitle = ko.observable(' Toggle Full Screen');

        var selectedPropertyId = ko.observable(0);
        var dmKey = ko.observable('');
        var DMAuthenticated = ko.observable(false);
        var propertyDetailResponse = ko.observable(undefined);
        var reportsContainer = ko.observable('');

        var serviceProducts = ko.observable(undefined);
        var productServicesProducts = ko.observable(undefined);
        var purchaseableReportsShoppingCart = ko.observableArray([]);
        var pendingApprovals = ko.observableArray([]);
        var shoppingCartLabel = ko.observable("");
        var shoppingCartEnable = ko.observable(false);
        var walletBalance = ko.observable(0);
        var creditBalance = ko.observable(0);
        var walletEnabled = ko.observable(false);
        var creditsEnabled = ko.observable(false);
        var hasPrice = ko.observable(false);
        var showRecentSearchBadge = ko.observable(true);
        var svLat = ko.observable(0);
        var svLng = ko.observable(0);
        var infoBoxRef = [];
        var addFundsSuccessMessage = ko.observable("");

        var additionalAddressLine = ko.observable("");

        var currentAPNZip = ko.observable("");
        var currentAPNFips = ko.observable("");

        var userZoomLevel = ko.observable(20);
        var activeInfobox = null;

        var mapFeature = null;
        var pinfobox = null;

        //BKFS Reports Start
        var propertyProfileModel = new propertyProfile.PropertyProfile();
        var reportPreparedForModel = new propertyProfile.ReportPreparedFor();
        var emailPropertyProfileReportModel = new propertyProfile.EmailPropertyProfileReport();

        var showMailDialog = ko.observable(false);

        var isCustomizeYourReportPopUp = ko.observable(false);
        //BKFS Reports End

        var propertyProfileResultStatus = ko.observable(0);
        var propertyProfileProfileId = ko.observable("");
        var propertyProfileProperty = ko.observable(undefined);
        var propertyProfileAvailableSections = ko.observableArray([]);
        var propertyProfileAvailableReportTypes = ko.observableArray

        var showReports = ko.observable(false);
        var showRequestCreditsMsgForProfile = ko.computed(function () {
            var showTheMessage = false;
            //console.log(serviceProducts());
            if (serviceProducts() != undefined && serviceProducts() != null && serviceProducts().length > 0) {
                for (var i = 0; i < serviceProducts().length; i++) {
                    if (propertyDetailService.getRemainingCreditsValue(serviceProducts()[i].UsageCountUsed, serviceProducts()[i].UsageLimitAssigned) == 0) {
                        showTheMessage = true;
                        break;
                    }
                }
            }
            return showTheMessage
        });

        var haslpsCredit = ko.computed(function () {
            return propertyDetailService.haslpsCredit();
        });

        var selectedProperty = ko.observable(undefined);
        var selectedAPN = ko.observable('');
        var purchaseableReportsShoppingCartItems = ko.observableArray([]);

        var predictiveTextBox = ko.observable('').extend({ maxLength: { params: 250, message: '250 or less characters are allowed.' } });
        var isSearchable = ko.observable(true);
        var hasZipFocused = ko.observable(false);
        var commonDialogModel = new commonDialog("addressSearchDialog");
        var isPredictiveInput = ko.observable(false);
        var dtResults = null;

        var currentLatitude = ko.observable(0);
        var currentLongitude = ko.observable(0);
        var isParcelEnabled = ko.observable(false);

        var isSearchInProgress = ko.observable(false);

        var activeAdvancedSearchUsed = ko.observable('Address');
        var activeAdvancedSearchDisplay = ko.observable('');

        var currentAddress = '';
        var currentOwner = '';
        var currentAPN = '';

        var map = null;
        var panorama = null;
        var bingKey = '';
        var searchManager = null;

        var isMarkable = false;
        var markers = [];


        var input = null;
        var searchBox = null;

        var directions = ["E", "N", "S", "W"];

        var isQuickSearchResult = ko.observable(false).extend({
            notify: 'always'
        });
        var searchBtnText = ko.observable("New Search");
        var defaultSettings = ko.observable(true);

        var currentUserFullAddress = null;
        var bgMarker = null;

        var recentSearchesList = ko.observableArray([]);

        //PurchasableProductsPaymentProcess
        var paymentModeView = ko.observable('paymentProcess/_cardInfo_ShoppingCart.html');
        var creditCardInformationModel = new paymentProcessModel.CreditCardInformation();
        var orderSummaryModel = new paymentProcessModel.OrderSummary();
        var walletModel = new paymentProcessModel.Wallet();
        var isProductShownForSelectedProperty = ko.observable(false);
        var paymentMode = ko.observable("1");
        var mobileViewProfileLinksVisible = ko.observable(false);

        var paymentModeList = ko.observableArray([
            { key: "Wallet", value: "0" },
            { key: "Credit Card", value: "1" }
        ]);

        var amountToAdd = ko.observable(0.0)
            .extend({ required: { message: 'Please enter amount.' } })
            .extend({
                pattern: {
                    params: '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$',
                    message: 'Invalid Amount. (format: ###.##)'
                }
            })
            .extend({ max: { params: 200.00, message: "Max amount : $200.00" } })
            .extend({ min: { params: 1.00, message: "Min amount : $1.00" } });


        var amountToAddString = ko.computed(function () {
            return '$' + parseFloat(amountToAdd()).toFixed(2);
        });

        var tax = ko.observable(0);
        var subTotal = ko.observable(0);
        var totalCredits = ko.observable(0);
        var Total = ko.observable(0);
        var subTotalDisplay = ko.observable('');
        var totalDisplay = ko.observable('');
        var taxDisplay = ko.observable('');
        var taxRateDisplay = ko.observable('');
        var canPurchaseWithWallet = ko.observable(true);
        var enablePayment = ko.observable(true);
        //var hasPrice = ko.observable(false);
        //var commonDialogModel2 = new commonDialog("AddFundsToWallet");
        var purchasableProdutsShoppingCartList = ko.observableArray([]);
        var purchasableProdutsWalletBalance = ko.observable(0);
        var purchasableProdutsCreditBalance = ko.observable(0);
        var purchasableProdutsWalletEnabled = ko.observable(false);
        var purchasableProdutsCreditsEnabled = ko.observable(false);
        var purchasableProdutsSelectedProperty = ko.observable(undefined);
        //var purchasableProdutsECommerceId = ko.observable(0);
        var purchasableProdutsTax = ko.observable(0.0);

        var APNFormatText = ko.observable("");
        var apnModelZipChangeEvent = ko.observable(false);
        var ownerModelZipChangeEvent = ko.observable(false);
        var streetLookupModelZipChangeEvent = ko.observable(false);

        var showPurchasableProducts = ko.observable(true);
        var isLoadCart = ko.observable(false);
        var isPropertyLatLongAvailable = ko.observable(true).extend({
            notify: 'always'
        });

        var midnightCommander = {
            "version": "1.0",
            "settings": {
                "landColor": "#330"
            },
            "elements": {
                "mapElement": {
                    "labelColor": "#FFFFFF",
                    "labelOutlineColor": "#000000"
                },
                "political": {
                    "borderStrokeColor": "#144B53",
                    "borderOutlineColor": "#00000000"
                },
                "point": {
                    "iconColor": "#0C4152",
                    "fillColor": "#000000",
                    "strokeColor": "#0C4152"
                },
                "transportation": {
                    "strokeColor": "#000000",
                    "fillColor": "#000000"
                },
                "highway": {
                    "strokeColor": "#158399",
                    "fillColor": "#000000"
                },
                "controlledAccessHighway": {
                    "strokeColor": "#158399",
                    "fillColor": "#000000"
                },
                "arterialRoad": {
                    "strokeColor": "#157399",
                    "fillColor": "#000000"
                },
                "majorRoad": {
                    "strokeColor": "#157399",
                    "fillColor": "#000000"
                },
                "railway": {
                    "strokeColor": "#146474",
                    "fillColor": "#000000"
                },
                "structure": {
                    "fillColor": "#AAB1B6"
                },
                "water": {
                    "fillColor": "#021019"
                },
                "area": {
                    "fillColor": "#AAB1B6"
                }
            }
        };




        hasZipFocused.subscribe(function (value) {
            //isSearchable(!value)
        });


        productServicesProducts.subscribe(function (newValue) {
            if (newValue && newValue.length > 0) {
                //$('#dvAvailableReports').slimScroll({
                //    height: '180px',
                //});
            }
        });

        isStreetView.subscribe(function (newValue) {

            if (isFullScreen())
                return;

            //console.log('isStreetView value ' + newValue);

            if (!newValue) {
                //toggleParcel(false);
                if (map) {
                    map.setView({
                        zoom: 18
                    });

                }
                session.showTopSearchControl(false);
                $('#dvmapContainer').removeClass().addClass('col-12 px-0');
                //$('#dvmapContainer').removeClass().addClass('w-100');

                $('#dvVisualResultContainer').removeClass();

            }
            else {
                //toggleParcel(true);
                session.showTopSearchControl(true);
                if (map) {
                    map.setView({
                        zoom: userZoomLevel()
                    });

                }
                $('#dvmapContainer').removeClass().addClass('order-2 col-lg-7 col-sm-12 col-xs-12 col-md-7 px-0');
                //$('#dvmapContainer').removeClass().addClass('pull-right w-40');

                //console.log('session.isMobile() ' + session.isMobile());

                if (propertyListing().length > 1) {
                    if (!session.isMobile())
                        $('#dvVisualResultContainer').removeClass().addClass('visualResultContainer');

                    window.location.hash = '#tblPropertyResults';

                }
            }
            if (map)
                console.log('Map Zooom : ' + map.getZoom());

        });

        isLatLongAvailable.subscribe(function (newValue) {


            if (isLatLongAvailable() == true) {
                if (!isMarkable) {
                    //initMapAutocomplete();
                }
                else {

                    header.headerSearchInProgress(false);

                    clearMarkers();

                    if (!isBingAvailable()) {
                        handleResultsWithNoMaps();
                        return;
                    }


                    //var infobox = null;
                    //var content = null;

                    if (searchResult() != null && searchResult() != undefined && searchResult().Properties != null && searchResult().Properties != undefined) {
                        if (isBasicSearchNoResult())
                            isBasicSearchNoResult(false);

                        //for (var i = 0; i < searchResult().Properties.length; i++) {
                        //    addInfoBoxForProperty(i)
                        //}

                        addInfoBoxForProperty(searchResult().Properties[0]);



                    }


                    //    setStreetView(searchResult().Properties[0].Latitude, searchResult().Properties[0].Longitude);

                    //if (propertyListing().length == 1)
                    //    map.setView({
                    //        zoom: 18
                    //    });


                    //if (propertyListing().length > 1 && propertyListing().length <= 10)
                    //    map.setView({
                    //        zoom: 16
                    //    });

                    //if (propertyListing().length > 10 && propertyListing().length <= 15)
                    //    map.setView({
                    //        zoom: 14
                    //    });

                    //if (propertyListing().length > 15 && propertyListing().length <= 20)
                    //    map.setView({
                    //        zoom: 12
                    //    });

                    //if (propertyListing().length > 20)
                    //    map.setView({
                    //        zoom: 10
                    //    });


                    //map.setTilt(0);


                    //setStreetView(searchResult().Properties[0].Latitude, searchResult().Properties[0].Longitude)
                    isStreetView(true);

                    if (session.isSelectedPropertyNeedToBeCentered()) {
                        map.setView({
                            center: new Microsoft.Maps.Location(searchResult().Properties[0].Latitude, searchResult().Properties[0].Longitude),
                        });
                    }

                    //selectedProperty(propertyListing()[0]);
                    //loadPropertyDetail(selectedProperty());





                    minimizeAdvanceSearch();
                    isSearchable(true);



                }

                isLatLongAvailable(false);

            }
        });

        function mapLinkClick(o, evt, data) {
            if (data != null && data.apn != null) {
                getPropertyDetailsFromMapLink(data.apn);
                if (session.showProfileButtons() == true)
                    getProfileReport();
                //console.log('link clicked');
            }
            return false;
        }

        function addInfoBoxForProperty(selectedProperty) {

            if (selectedProperty.isLocated == undefined || selectedProperty.isLocated == false) {
                console.log('isSearchByClick ' + isSearchByClick());

                if (isSearchByClick()) {
                    selectedProperty.latitude = currentTargetLocation().latitude;
                    selectedProperty.longitude = currentTargetLocation().longitude;
                    LoadAllDetails(selectedProperty, true);
                    isSearchByClick(false);
                    return;
                }
                var url = config.getPropertyLatLongUrl;

                https.get(url,
                    {
                        apn: selectedProperty.APN,
                        zip: selectedProperty.ZIP,
                        county: selectedProperty.County,
                        state: selectedProperty.State,
                        address: selectedProperty.Address,
                        format: 'json'
                    }
                ).then(function (data) {


                    if (data.IsLatLongAvailable) {
                        selectedProperty.Latitude = data.Latitude;
                        selectedProperty.Longitude = data.Longitude;
                    }
                    LoadAllDetails(selectedProperty, true);

                })
                    .fail(function (data) {
                        console.log(data);
                    });
            }
            else {
                LoadAllDetails(selectedProperty, false); 
                
            }

            //addInfoBox(selectedProperty);
        }

        function LoadAllDetails(selectedProperty, isAddInfoBox) {
            if (isAddInfoBox)
                addInfoBox(selectedProperty);

            loadPropertyDetail(selectedProperty);
            showReports(false);
            showInfoBox(selectedProperty);
        }

        function addInfoBox(selectedProperty) {
            var infobox = null;
            var content = null;
            var markerTitle = '';

          


            if (currentOwner.length > 0 && currentAddress.length > 0 && currentAPN.length > 0) {
                markerTitle = 'Owner : ' + currentOwner + ', Address :  ' + currentAddress + ', APN :  ' + currentAPN;
            }


            var location = new Microsoft.Maps.Location(selectedProperty.Latitude, selectedProperty.Longitude);

            var pin = new Microsoft.Maps.Pushpin(location);

            infobox = new Microsoft.Maps.Infobox(
                location,
                {
                    visible: false,
                    showCloseButton: false
                });

           // Microsoft.Maps.Events.addHandler(infobox, 'click', handleClickInfoBox);

           

            content = buildInfoBoxContent(selectedProperty);

            pin.metadata = {
                title: '',
                content: content
            };

           

            infobox.apn = currentAPN;


            Microsoft.Maps.Events.addHandler(pin, 'mouseover', function (args) {
                hideInfoBoxes();
                infobox.setOptions({
                    location: args.target.getLocation(),
                    description: args.target.metadata.content,
                    visible: true,
                    showCloseButton: false,
                    maxHeight: 170
                });
                if ($('a.getProfileLinkInfoBox') != null) {
                    var langText = propertySearchCommon.getPropProfileButtonText();
                    if (langText.length > 0)
                        $('a.getProfileLinkInfoBox').text(langText);
                }
            });


            Microsoft.Maps.Events.addHandler(pin, 'click', function (args) {
                hideInfoBoxes();
                activeInfobox = infobox;
                var clickedAPN = $(args.target.metadata.content)[0].lastChild.childNodes[2].children["0"].dataset.apn;
                infobox.setOptions({
                    location: args.target.getLocation(),
                    description: args.target.metadata.content,
                    visible: true,
                    showCloseButton: false,
                    maxHeight: 170
                });

                getPropertyDetailsFromMapLink(clickedAPN);
                //console.log('Pin clicked : ' + clickedAPN);

            });

            infobox.setMap(map);
            Microsoft.Maps.Events.addHandler(infobox, 'click', function (e) { handleInfoBoxClose(e.originalEvent.target.id, infobox); });

            selectedProperty.isLocated = true;
            infoBoxRef.push(infobox);
            map.entities.push(pin);
        }

       

        function handleResultsWithNoMaps() {
            selectedProperty(propertyListing()[0]);
            loadPropertyDetail(selectedProperty());
            minimizeAdvanceSearch();
            isSearchable(true);
            isStreetView(true);
            isLatLongAvailable(false);
        }

        function handleInfoBoxClose(id, infobox) {
            if (id == 'InfoBoxClose') {
                infobox.setOptions({
                    visible: false,
                });
            }
        }

        function buildInfoBoxContent(selectedProperty) {
            var content = null;
            currentAddress = getAddressString(selectedProperty);

            currentOwner = selectedProperty.Owner;
            currentAPN = selectedProperty.APN;
            content = null;

            // Any changes to the 'content' div tag please check - Microsoft.Maps.Events.addHandler(pin, 'click', function (args)) event
            content = '<div id="content" style="background-color:white;padding:0px;">' +
                '<div id="siteNotice">' +
                '</div>' +
                '<span class="infoOwner">' + selectedProperty.Owner + '</span>' +
                '<div id="bodyContent">' +
                '<p class="infoAddress m-0"><b>Address : </b>' + currentAddress + '</p>' +
                '<p class="infoAddress m-0"><b>APN : </b>' + currentAPN + '</p>';

            if (!showReports()) {
                content = content + '<p class="infoAddress m-0">';
                //var linkText = session.showProfileButtons() == true ? 'Get Property Profile' : 'Property Details';
                if (session.showProfileButtons()) {
                    var linkText = propertySearchCommon.getPropProfileButtonText();
                    content += '<a class="blueLink getProfileLinkInfoBox btn-link" tabindex="0" onkeypress= \'$("#btnmapLinkClick").trigger( "click", {apn: "' + currentAPN + '"} )\' data-apn="' + currentAPN + '"  onclick= \'$("#btnmapLinkClick").trigger( "click", {apn: "' + currentAPN + '"} )\' data-apn="' + currentAPN + '">' + linkText + '</a></p>';
                }
            }
            content += "<div class='d-flex justify-content-center'><button class='btn btn-primary' id='InfoBoxClose'>Close</button></div>"
            

            content = content + '</div>' +
                '</div>';
            return content;
        }

        //$("#btnmapLinkClick").on("click", function () {
        //    alert($(this).text());
        //});

        function setStreetView(lat, lng) {
            if (!isBingAvailable() || !isPropertyLatLongAvailable()) {
                if (panorama) {
                    panorama.dispose();
                    panorama = null;
                }
                return;
            }


            svLat(lat);
            svLng(lng);
            //panorama.setView({
            //    center: new Microsoft.Maps.Location(svLat(), svLng()),
            //    heading: 90,
            //    pitch: -30,
            //    });
            if (panorama) {
                panorama.dispose();
                panorama = null;
            }
            //console.log('mapTypeId set from setStreetView');
            panorama = new Microsoft.Maps.Map(document.getElementById('pano'), {
                credentials: bingKey,
                zoom: 16,
                showMapTypeSelector: false,
                mapTypeId: Microsoft.Maps.MapTypeId.streetside,
                center: new Microsoft.Maps.Location(svLat(), svLng())
                //streetsideOptions: {
                //    onErrorLoading: changeStreetToAerial
                //}
            });

            if (panorama != null) {
                panorama.setOptions({
                    streetsideOptions: {
                        overviewMapMode: Microsoft.Maps.OverviewMapMode.hidden,
                        showCurrentAddress: false,
                        showProblemReporting: false,
                        showExitButton: false,
                        disablePanoramaNavigation: false,
                        showHeadingCompass: false,
                        showZoomButtons: false,
                        locationToLookAt: new Microsoft.Maps.Location(svLat(), svLng()),
                        streetsideOptions: {
                            onErrorLoading: changeStreetToAerial
                        }
                    }
                });
            }


            if (isLoadCart()) {
                togglePropertyReportView();
                showReports(false);
            }
        }

        function changeStreetToAerial() {
            //console.log('mapTypeId set from changeStreetToAerial');
            if (panorama == null || panorama == undefined) {
                panorama = new Microsoft.Maps.Map(document.getElementById('pano'));
            }
            panorama.setView({
                center: new Microsoft.Maps.Location(svLat(), svLng()),
                mapTypeId: Microsoft.Maps.MapTypeId.aerial,
                showZoomButtons: false,
                zoom: 25
            });

            pin = new Microsoft.Maps.Pushpin(new Microsoft.Maps.Location(svLat(), svLng()), {
                text: '',
                title: '',
                subTitle: 'Street view not available.'
            });
            panorama.entities.push(pin);

        }

        function setDMKey() {
            return utlities.getDMKey().done(function (dmKeyResponse) {
                if (dmKeyResponse && dmKeyResponse.HasError == false) {
                    dmKey(dmKeyResponse.DMKey);

                }
                else {
                    //console.log('Parcel Key Error : ' + dmKeyResponse.ErrorMessage);
                }

            });
        }



        $('#pac-input').on("keypress", function (e) {
            if (e.which == 13) {
                return false;
            }
        });

        function searchButtonClick() {
            //showMailDialog(false);
            activeAdvancedSearchUsed('Address');
            vm.isBasicSearchNoResult(false);
            isQuickSearchNoResultError(false);
            radiusSearch.refreshRangeSlider();
            propertySearchCommon.searchDetails.searchContextChanged(Date());
            if (isSearchInProgress())
                return;

            showReports(false);
            if (!session.llaAccepted()) {
                session.showLLAAgreement();
                return;
            }

            var searchString = $('#pac-input').val();



            if (searchString.length == 0) {
                commonDialogModel.show("Find Your Property", "Please provide the address to search.");
                return;
            }
            isPredictiveSearch(true);
            isSearchInProgress(true);




            geocodeQuery(searchString);


            isQuickSearchResult(true);
        }

        function createOrder() {
            router.navigate("/#orders");
        }



        function doSearchByAddress(address) {
            if (!session.llaAccepted()) {
                session.showLLAAgreement();
                return;
            }

            //var address = addressVal;
            var type = '';
            var address1 = '';
            var address2 = '';

            var city = '';
            var state = '';
            var zip = '';
            var street = '';
            var addAddressLine = '';


            if (address.addressLine == undefined || address.countryRegion != 'United States') {
                //alert.error('Not an Valid US address.');
                isSearchInProgress(false);
                handleNoResult();
                return;
            }

            isFindYourPropertyEnabled(false);

            //console.log(address);



            city = address.locality;
            state = address.adminDistrict;
            zip = address.postalCode;
            street = address.addressLine;

            if (additionalAddressLine().length > 0) {
                addAddressLine = additionalAddressLine();
            }
            else {
                addAddressLine = '';
            }

            isMarkable = true;
            try {
                searchResult(undefined);
                addressSearch.addressSearch(searchResult, {
                    city: city, state: state, zip: zip, streetName: street, additionalAddressLine: addAddressLine
                }, isSearchable).done(function () {
                    isSearchInProgress(false)


                }).fail(Failed);
            }
            catch (err) {
                Failed(err);
            }

            //console.log('result after search' + result);
        }

        // specifically for handling Lat/long input in quick search.
        APNsearchResult.subscribe(function (newValue) {
            if (newValue && newValue.Properties.length > 0) {
                searchResult(newValue);
            }
            else {
                if (isLatLongSearchRequest()) {
                    //alert.warning('handle no property');
                    handleNoResultLatLongSearchLocation();
                    isLatLongSearchRequest(false);
                }
                else {
                    searchResult(newValue);
                    setMapAndMarkPushPin(currentTargetLocation(), 17);
                }
            }
        });

        function setUserSessionValues(searchResult) {
            if (searchResult != undefined && searchResult.UserProducts != undefined) {
                session.isEmployee(searchResult.UserProducts.IsEmployee);
                propertySearchCommon.hasAnyProduct(searchResult.UserProducts);
                propertySearchCommon.haslpsCredit(searchResult.UserProducts);
                propertySearchCommon.setProfileSelectedLanguage(searchResult.UserProducts.ProfileLanguagePreference);
                setProfileButtonVisibility();
            }
            /// console.log("*****" + session.isEmployee() + "***" + session.hasLPSCredit() + "***" + session.showProfileButtons());
        };

        function setProfileButtonVisibility() {
            var buttonVisibility = session.hasLPSCredit() || session.isEmployee();
            session.showProfileButtons(buttonVisibility)
        }

        searchResult.subscribe(function (newValue) {
            var result = searchResult();
            setUserSessionValues(newValue);

            if (result) {

                if (result.Properties.length > 0) {

                    //   if (result.Properties[0].IsAddressLocatable == false) {
                    ////   if (!result.Properties[0].IsPropertyLatLongAvailable) {
                    //       cleartMapResults()
                    //   handleNoResult();
                    //   //isBingAvailable(false);

                    //       return;
                    //   }



                    //cleartMapResults();
                    result.Properties.sort(function (a, b) {
                        var nameA = a.Owner.toUpperCase(); // ignore upper and lowercase
                        var nameB = b.Owner.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }

                        // names must be equal
                        return 0;
                    });

                    propertyListing([]);
                    propertyListing(result.Properties);

                    isLatLongAvailable(true);
                    //isLatLongAvailable(false);
                }
                else if (result.Messages != null && result.Messages.length > 0) {
                    var msg = '';
                    var handled = false;
                    jQuery.each(result.Messages, function (i, msgItem) {
                        if (msgItem.Message.indexOf('Bing') > -1) {
                            alert.warning('We have experienced a minor technical issue. Please use the Advanced Search option.');
                            showAdvanceSearch();
                            handled = true;
                            return;
                        }
                        msg += msgItem.Message + '<br />';
                    });
                    if (handled === false) {
                        if (msg.indexOf('Zipcode Service Error') > -1) {
                            session.zipCodeServiceDown();
                            isFindYourPropertyEnabled(true);
                        }
                        else
                            alert.error(msg);
                        isSearchable(true);

                    }
                }
                else {
                    handleNoResult();
                }
            }
        });

        searchType.subscribe(function (value) {
            //console.log(value);
            $('#pac-input').val('').trigger("change");
            $('#pac-input-APN').val('').trigger("change");
            //$('#pac-input-County').val('').trigger("change");

            if (value == "Address") {
                $('#pac-input').trigger("focus");
            }
            else {
                $('#pac-input-APN').trigger("focus");
            }
        });


        isAdvanceSearchEnabled.subscribe(function (isEnabled) {
            if (isEnabled) {
                //addressModel.reset();
                showAdvanceSearchBadge(false);

                if (!isQuickSearchNoResultError())
                    isBasicSearchNoResult(false);

                isFindYourPropertyEnabled(false);
                showRecentSearchBadge(false);
                session.showTopSearchControl(true);
                //    $('#page-wrapper').css("min-height",  "1200px");
            }
            else {
                //console.log('isAdvanceSearchFromAddressSearch ' + isAdvanceSearchFromAddressSearch());
                showAdvanceSearchBadge(true);

                if (!isStreetView())
                    session.showTopSearchControl(false);
                //if (isAdvanceSearchFromAddressSearch() && !isMinimizeAdvanceSearch()) {
                //    isFindYourPropertyEnabled(true);
                //}

                if (propertyListing().length == 0 && isStreetView() == false) {
                    isFindYourPropertyEnabled(true);
                }

                showRecentSearchBadge(true);
            }
        });

        //toggleParcel.subscribe(function (toggle) {
        //    if (map && map.layers && map.layers.length > 0)
        //        map.layers[0].setVisible(toggle);
        //});


        //-------------------------------------------------------------------------------------------------------------------------------------
        //BKFS Reports Start

        reportPreparedForModel.pname.subscribe(function (value) {
            if (propertyProfileModel.enableReportPreparedFor()) {
                //       console.log("In subscribe : pname");
                RebuildReportURLs("pname", value, true, true);
            }
        });

        reportPreparedForModel.pcompany.subscribe(function (value) {
            if (propertyProfileModel.enableReportPreparedFor()) {
                //console.log("In subscribe : pcompany");
                RebuildReportURLs("pcompany", value, true, true);
            }
        });

        reportPreparedForModel.paddress1.subscribe(function (value) {
            if (propertyProfileModel.enableReportPreparedFor()) {
                //console.log("In subscribe : paddress1");
                RebuildReportURLs("paddress1", value, true, true);
            }
        });

        reportPreparedForModel.paddress2.subscribe(function (value) {
            if (propertyProfileModel.enableReportPreparedFor()) {
                //  console.log("In subscribe : paddress2");
                RebuildReportURLs("paddress2", value, true, true);
            }
        });

        reportPreparedForModel.pcsz.subscribe(function (value) {
            if (propertyProfileModel.enableReportPreparedFor()) {
                //        console.log("In subscribe : pcsz");
                RebuildReportURLs("pcsz", value, true, true);
            }
        });

        function ChangeProfileURL() {
            var int = 0;
            $.each(propertyProfileModel.availableSections(), function (index, data) {
                if (data.CheckBoxValue() === true) {
                    int = int + data.Value();
                }
            });
            RebuildReportURLs("mask", int, false, true);
            return true;
        }

        function RebuildReportURLs(queryStringParameter, value, includeIndividualPages, includeReports) {
            if (value != null && value != "") {
                if (includeIndividualPages) {
                    if (propertyProfileModel.availableSections().length > 0) {
                        for (var i = 0; i < propertyProfileModel.availableSections().length; i++) {
                            if (propertyProfileModel.availableSections()[i].ProfileURL() != null && propertyProfileModel.availableSections()[i].ProfileURL() != "") {
                                propertyProfileModel.availableSections()[i].ProfileURL(replaceURLParameters(propertyProfileModel.availableSections()[i].ProfileURL(), queryStringParameter, value));
                                //propertyProfileModel.availableSections()[i].ProfileURL(propertyProfileModel.availableSections()[i].ProfileURL() + "&" + queryStringParameter + "=" + value);
                            }
                        }
                    }
                }
                if (includeReports) {
                    if (propertyProfileModel.availableReportTypes().length > 0) {
                        for (var i = 0; i < propertyProfileModel.availableReportTypes().length; i++) {
                            if (propertyProfileModel.availableReportTypes()[i].ProfileURL() != null && propertyProfileModel.availableReportTypes()[i].ProfileURL() != "") {
                                //propertyProfileModel.availableReportTypes()[i].ProfileURL(propertyProfileModel.availableReportTypes()[i].ProfileURL() + "&" + queryStringParameter + "=" + value);
                                propertyProfileModel.availableReportTypes()[i].ProfileURL(replaceURLParameters(propertyProfileModel.availableReportTypes()[i].ProfileURL(), queryStringParameter, value));
                            }
                        }
                    }
                }
            }
        }

        function replaceURLParameters(url, queryStringParameter, value) {
            var vars = [], hash;
            var urlWithoutParams = url.slice(0, url.indexOf('?'));
            var finalURLParams = "";
            var replaced = false;
            var hashes = url.slice(url.indexOf('?') + 1).split('&');
            for (var i = 0; i < hashes.length; i++) {
                hash = hashes[i].split('=');
                if (hash[0] === queryStringParameter) {
                    hash[1] = value;
                    replaced = true;
                }
                if (i > 0)
                    finalURLParams = finalURLParams + "&" + hash[0] + "=" + hash[1];
                else
                    finalURLParams = hash[0] + "=" + hash[1];
            }
            if (!replaced) {
                finalURLParams = finalURLParams + "&" + queryStringParameter + "=" + value;
            }
            return urlWithoutParams + "?" + finalURLParams;
        }

        function getReport(selectedReport, event) {
            if (session.userId != null) {
                $('#mdEmailPropertyProfileReport').modal('hide');
                $('#mdPropertyProfile').modal('hide');
                if (selectedReport.Id == 87 || selectedReport.Id == 85 || selectedReport.Id == 91) {
                    var url = config.getBKFSTPReportsUrl;
                    var request = "";
                    if (selectedReport.Id == 87 || selectedReport.Id == 85) {
                        request = {
                            OperationId: session.defaultOpertionId(),
                            ServiceId: selectedReport.Id,
                            BrandId: session.brandId,
                            Fips: selectedProperty().FIPS,
                            APN: selectedProperty().APN
                        };
                    }
                    if (selectedReport.Id == 91) {
                        request = {
                            OperationId: session.defaultOpertionId(),
                            ServiceId: selectedReport.Id,
                            BrandId: session.brandId,
                            Fips: selectedProperty().FIPS,
                            APN: selectedProperty().APN,
                            Owner: selectedProperty().Owner,
                            Address: selectedProperty().Address,
                            City: selectedProperty().City,
                            County: selectedProperty().County,
                            State: selectedProperty().State,
                            Zip: selectedProperty().ZIP,
                            Latitude: selectedProperty().Latitude,
                            Longitude: selectedProperty().Longitude
                        };
                    }
                    https.get(url, request)
                        .then(function (data) {
                            //console.log(data);
                            session.CheckPopUpBlock(data, null, "** Reports open in a new window. Please disable your popup blocker and try again.");
                            //window.open(data);
                        })
                        .fail(function (data) {
                            console.log(data);
                        });
                }
                else if (selectedReport.Id == 84) {
                    //getProfileCustomSettingsForUser(session.userId).done(function (data) {
                    //	if (data != null) {
                    //  console.log('open profile report modal');
                    reportPreparedForModel.reset();
                    propertyProfileService.getPropertyProfileInfo(selectedProperty().FIPS, selectedProperty().APN, null).done(function (result) {
                        propertyProfileModel.resultStatus(result.ResultStatus);
                        propertyProfileModel.profileId(result.ProfileId);
                        propertyProfileModel.property(result.Property);
                        propertyProfileModel.availableSections(propertyProfileService.loadToKOArray(result.AvailableSections).slice(0));
                        propertyProfileModel.availableReportTypes(propertyProfileService.loadToKOArray(result.AvailableReportTypes).slice(0));
                        propertyProfileModel.assignedCredits(selectedReport.UsageLimitAssigned);
                        propertyProfileModel.usedCredits(selectedReport.UsageCountUsed);
                        propertyProfileModel.enableReportPreparedFor(result.EnableReportPreparedForSection);

                        //propertyProfileModel.availableReportTypesByReportType = _groupBy(result.AvailableReportTypes, 'ReportType')
                    }).then(showPropertyProfilePopUp).fail(function () {
                        alert.error('Error getting Property Profile Info');
                    });
                    //	}
                    //	else {
                    //		console.log('Custom Comparable Settings has not been enabled');
                    //		alert.error('Custom Comparable Settings has not been enabled');
                    //		return;
                    //	}
                    //});
                }
            }
            else {
                console.log('User Id is null');
                alert.error('User not logged in');
                return;
            }
        }

        function getProfileReport() {
            vm.closeMultiplePropertyWarning();
            var viewId = 1;
            var profileData;

            if (session.selectedProfLanguage != null && (session.selectedProfLanguage.langageCode() != 'en' && session.selectedProfLanguage.langageCode() != '-')) {
                profileData = vm.profileLinks().filter(function (dt) {
                    return dt.LanguageCode == session.selectedProfLanguage.langageCode();
                });
            }
            else {
                if (session.selectedProfLanguage.langageCode() == '-') {
                    profileData = vm.profileLinks().filter(function (dt) {
                        return dt.LanguageCode == null;
                    });
                }
            }

            if (profileData != null && profileData.length > 0)
                viewId = profileData[0].Value;

            propertyProfileService.buildProfileURL(selectedProperty().APN, selectedProperty().FIPS, undefined, viewId)
                .then(function (data) {
                    if (data && data.length > 0)
                        if (data.toUpperCase() == "NOPRODUCTLIMIT")
                            alert.warning('Please contact your Sales Executive for information about additional credits required to complete your request.');
                        else {
                            try {
                                session.CheckPopUpBlock(data, null, "** Reports open in a new window. Please disable your popup blocker and try again.");
                                //window.open(data);
                            } catch (ex) {
                                alert.error('We are sorry, we could not open web page');
                            }
                        }
                    else
                        alert.warning('Property profile not available. Please contact customer support');
                })
                .fail(function (data) {
                    console.log(data);
                });
        }

        function handleNonExistingLangSeletion() {
            var dataToHighlight; var fltrd = [];

            if (profileLinks() == null) profileLinks([]); //set to empty list so that it does not break on filter

            if (profileLinks().length == 1) { //if i have only one option set that language as button language
                fltrd.push(profileLinks()[0]);
            }
            else {// otherwise check if selected language is there in the available list
                fltrd = profileLinks().filter(function (d) {
                    return d.LanguageCode == session.selectedProfLanguage.langageCode();
                });
            }

            if (fltrd != null && fltrd.length > 0) { // if user has a valid preffered language then set it
                dataToHighlight = {
                    LanguageCode: fltrd[0].LanguageCode,
                    Language: fltrd[0].Language,
                    ReportType: fltrd[0].ReportType
                };
            }

            if (dataToHighlight == null) {
                dataToHighlight = {
                    LanguageCode: '-',
                    Language: 'English',
                    ReportType: 'Profile'
                };
            }
            propertySearchCommon.openReport(dataToHighlight, null, true);
        }

        function getProfileLinks() {
            propertyProfileService.buildProfileURLs(selectedProperty().APN, selectedProperty().FIPS)
                .then(function (data) {

                    if (data && data.HasErrors == true) {
                        // handleProfileLinkMessages(data);
                    }
                    else {
                        if (data.Data == null)
                            data.Data = [];

                        profileLinks(data.Data);
                        if (session.isMobile()) {
                            mobileViewProfileLinksVisible(false);
                        }

                        showProfileLinks(data.Data != null && data.Data.length > 1);

                        handleNonExistingLangSeletion();
                    }
                })
                .fail(function (data) {
                    console.log(data);
                });
        }

        function showPropertyProfilePopUp() {
            //console.log(propertyProfileModel.profileId());
            //console.log(propertyProfileModel.availableReportTypes());
            //console.log(propertyProfileModel.availableSections());

            //showMailDialog(true);
            isCustomizeYourReportPopUp(false);
            $('#mdEmailPropertyProfileReport').modal('hide');
            $('#mdPropertyProfile').modal('show');
        }

        function propertyProfilePopup() {
            $('#mdCustomComparables').modal('show');
        }

        function customizeYourReport() {
            isCustomizeYourReportPopUp(true);
            $('#mdCustomizeYourReport').on('shown.bs.modal', function () {
                session.validateInput();
            });
            propertyProfileService.getAvailableSectionsForReportCustomization(selectedProperty().FIPS, selectedProperty().APN)
                .done(function (result) {
                    propertyProfileModel.availableSections(propertyProfileService.loadToKOArray(result.AvailableSections).slice(0));
                    propertyProfileModel.availableReportTypes(propertyProfileService.loadToKOArray(result.AvailableReportTypes).slice(0));
                }).then(function () {
                    $('#mdCustomizeYourReport').modal('show');
                    setTimeout(function () {
                        $('#mdCustomizeYourReport').on('hidden.bs.modal', function (e) {
                            $('#btnCustomComparable').eq(0).focus();
                        });
                    }, 200);
                }).fail(function () {
                    $('#mdCustomizeYourReport').modal('hide');
                    alert.error('Error getting Customize Your Report settings');
                });
        }

        function placeOrderForThisProperty() {
            session.orderForPropertyAddress.reset();
            session.orderForPropertyAddress.updateFromSearch(selectedProperty());
            //session.orderForPropertyAddress.owner(selectedProperty().Owner);
            //session.orderForPropertyAddress.address(selectedProperty().Address);
            //session.orderForPropertyAddress.zip(selectedProperty().ZIP);
            //session.orderForPropertyAddress.city(selectedProperty().City);
            //session.orderForPropertyAddress.county(selectedProperty().County);
            //session.orderForPropertyAddress.fips(selectedProperty().FIPS);
            //session.orderForPropertyAddress.state(selectedProperty().State);
            //session.orderForPropertyAddress.apn(selectedProperty().APN);
            //session.orderForPropertyAddress.addressInURL(false);

            router.navigate('#orders');
        }


        function saveDefaultSetting() {
            var selected = new Array();
            $.each(propertyProfileModel.availableSections(), function (index, data) {
                if (data.CheckBoxValue() === true) {
                    selected.push(data.Value);
                }
            });
            propertyProfileService.setReportCustomizationForUser(selected)
                .done(function (result) {
                    alert.success('Customize Your Report settings updated successfully.');
                    $('#mdPropertyProfile').modal('hide');
                }).fail(function () {
                    alert.error('Error Updating Customize Your Report settings');
                });
        }

        function EmailPropertyProfileReportPopUp(data, event) {
            //if (showMailDialog()==true) {
            if (data.Name() != null && data.ProfileURL() != null && data.Name() != '' && data.ProfileURL() != '') {
                emailPropertyProfileReportModel.reset();
                emailPropertyProfileReportModel.selectedReport(data.Name());
                emailPropertyProfileReportModel.selectedReportURL(data.ProfileURL());
                showEmailPropertyProfileReports();
            }
            //}
        }
        //-- multilingual profile selection from links and drop down
        function selectedDropdownValue(data, event) {

            data.Key = '';
            data.Value = event.currentTarget.text;
            propertySearchCommon.setProfileSelectedLanguage(data);

            return false;
        }

        //--End multilingual profile selection from links and drop down

        //function CloseSendEmailPropertyProfileReportPopUp()
        //{
        //    showMailDialog = false;
        //    $('#mdEmailPropertyProfileReport').modal('hide');
        //}

        function showEmailPropertyProfileReports() {
            // if (showMailDialog() == true)
            $('#mdEmailPropertyProfileReport').modal('show');
        }

        function SendEmailPropertyProfileReport() {
            if (vm.emailPropertyProfileReportModelError != null && vm.emailPropertyProfileReportModelError != 'undefined' && vm.emailPropertyProfileReportModelError().length > 0) {
                vm.emailPropertyProfileReportModelError.showAllMessages();
                return;
            }

            var url = config.emailPropertyProfileReportUrl;
            var request = {
                "UserId": session.userId,
                "OperationId": session.defaultOpertionId(),
                "EmailAddress": emailPropertyProfileReportModel.emailAddresses(),
                "EmailSubject": emailPropertyProfileReportModel.emailSubject(),
                "EmailMessage": emailPropertyProfileReportModel.emailMessage(),
                "ReadReceipt": emailPropertyProfileReportModel.readReceipt(),
                "ReportURL": emailPropertyProfileReportModel.selectedReportURL(),
                "ReportName": emailPropertyProfileReportModel.selectedReport(),
                "SelectedProperty": {
                    "UserId": session.userId,
                    "Apn": selectedProperty().APN,
                    "Fips": selectedProperty().FIPS,
                    "Address1": selectedProperty().Address,
                    "Address2": "",
                    "Zip": selectedProperty().ZIP,
                    "City": selectedProperty().City,
                    "State": selectedProperty().State,
                    "Zip4": selectedProperty().ZIP4,
                    "Owner": selectedProperty().Owner,
                },
            };

            return https.post(url, request)
                .done(function (data) {
                    $('#mdEmailPropertyProfileReport').modal('hide');
                    if (data != null) {
                        if (data.HasErrors == false) {
                            alert.success("Email Sent", "Email Property Profile Report");
                        }
                        else {
                            alert.error(data.Messages[0].Message, "Email Property Profile Report");
                            //alert.error('Error while sending email', "Email Property Profile Report");
                        }
                    }
                    else {
                        alert.error('Internal Server Error', "Email Property Profile Report");
                    }
                });
        }
        //BKFS End
        //-------------------------------------------------------------------------------------------------------------------------------------


        function handleNoResult() {
            isSearchInProgress(false);

            if (!isStreetView()) {
                clearMarkers();
                propertyListing([]);

            }
            isQuickSearchNoResultError(true);
            // always oops will be shown Ref Bug #86823
            showAdvanceSearch();
            isBasicSearchNoResult(true);

            if (isStreetView())
                isStreetView(false);

            //if(isAdvanceSearchEnabled())
            //alert.warning('We couldn’t find the address you were looking for. Try our advanced search to help you get to the right place.', {timeout:8000});

            return;

            if (isPredictiveSearch()) {
                showAdvanceSearch();

            }
            else {

                //if ($('.modal-backdrop').length > 0) {
                //    $('#addressSearchDialog').modal('hide');
                //    $('body').removeClass('modal-open');
                //    $('.modal-backdrop').remove();
                //}

                //commonDialogModel.show("Property search", "No property found.");
                $('#mdNoResult').modal('show');
                //closeAdvanceSearch();
                closeAdvanceSearchWithoutClearingData();
                isSearchable(true);
                isFindYourPropertyEnabled(true);
            }

        }


        function canDeactivate() {
            if (!session.hasLoggedOut()) {
                var urlInComing = window.location.href;
                var stopLength = urlInComing.length;
                if (urlInComing.indexOf("?") > 0) {
                    stopLength = urlInComing.indexOf("?");
                }
                var routeURL = urlInComing.substring(urlInComing.indexOf("#"), stopLength);
                window.history.pushState(null, "", window.location.href);
                if (routeURL.toLowerCase() == "#login" || routeURL.toLowerCase() == "#")
                    return false;
                window.history.pushState(null, "", window.location.href);
                window.onpopstate = function () {
                    window.history.pushState(null, "", window.location.href);
                };
            }
            return true;
        }

        function ScrollToElement(id) {
            //var etop = $('#' + id).offset().top;
            //var etop = $('.modal_Body_Height .container-fluid #' + id).offset().top - $('.modal_Body_Height .container-fluid').offset().top;
            //$('.modal_Body_Height, #mdPropertyProfile').animate({ scrollTop: etop }, 500);
            $('.modal-body', '#mdPropertyProfile').scrollTop(900);
        }

        var vm = {
            // attached: initMap,
            activate: activate,
            canDeactivate: canDeactivate,
            deactivate: function () {
                if (!session.hasLoggedOut()) {
                    var urlInComing = window.location.href;
                    var stopLength = urlInComing.length;
                    if (urlInComing.indexOf("?") > 0) {
                        stopLength = urlInComing.indexOf("?");
                    }
                    var routeURL = urlInComing.substring(urlInComing.indexOf("#"), stopLength);
                    window.history.pushState(null, "", window.location.href);
                    if (routeURL.toLowerCase() == "#login") {
                        return false;
                    }
                }
                if (searchManager != null) {
                    searchManager = null;
                }

                if (map != null) {
                    map.dispose();
                    map = null;
                }

            },
            searchType: searchType,
            APNSearchAPNNumber: APNSearchAPNNumber,
            APNSearchCounty: APNSearchCounty,
            APNSearchButtonClick: APNSearchButtonClick,
            isAdvanceSearchEnabled: isAdvanceSearchEnabled,
            showAdvanceSearch: showAdvanceSearch,
            clearQuickSearch: clearQuickSearch,
            showAdvanceSearchBadge: showAdvanceSearchBadge,
            isBasicSearchNoResult: isBasicSearchNoResult,
            closeAdvanceSearch: closeAdvanceSearch,
            closeAdvanceSearchWithoutClearingData: closeAdvanceSearchWithoutClearingData,
            isFindYourPropertyEnabled: isFindYourPropertyEnabled,
            searchProperty: searchProperty,
            isAdvanceSearchFromAddressSearch: isAdvanceSearchFromAddressSearch,
            showRadiusSearchSlideBar: showRadiusSearchSlideBar,
            minimizeAdvanceSearch: minimizeAdvanceSearch,
            searchResult: searchResult,
            showBasicSearch: showBasicSearch,
            addressSearch: addressSearch,
            ownerSearch: ownerSearch,
            apnSearch: apnSearch,
            streetSearch: streetSearch,
            apnModel: apnModel,
            ownerModel: ownerModel,
            addressModel: addressModel,
            showRequestCreditsMsgForProfile: showRequestCreditsMsgForProfile,
            haslpsCredit: haslpsCredit,
            streetLookupModel: streetLookupModel,
            searchPropertyByAdress: searchPropertyByAdress,
            searchPropertyByOwner: searchPropertyByOwner,
            searchPropertyByAPN: searchPropertyByAPN,
            searchPropertyByStreet: searchPropertyByStreet,
            directions: directions,
            searchPropertyByStreet: searchPropertyByStreet,
            GetStatesList: GetStatesList,
            stateList: stateList,
            ownerCountyList: ownerCountyList,
            apnCountyList: apnCountyList,
            streetCountyList: streetCountyList,
            propertyListing: propertyListing,
            ownerfips: ownerfips,
            streetfips: streetfips,
            apnfips: apnfips,
            ownerfipsNo: ownerfipsNo,
            streetfipsNo: streetfipsNo,
            apnfipsNo: apnfipsNo,
            cleartMapResults: cleartMapResults,
            isPredictiveSearch: isPredictiveSearch,
            searchButtonClick: searchButtonClick,
            predictiveTextBox: predictiveTextBox,
            getPropertyDetail: getPropertyDetail,
            getAddressString: getAddressString,
            getAddressStringNotZip: getAddressStringNotZip,
            isSearchable: isSearchable,
            hasZipFocused: hasZipFocused,
            commonDialogModel: commonDialogModel,
            isPredictiveInput: isPredictiveInput,
            isStreetView: isStreetView,
            propertyDetailResponse: propertyDetailResponse,
            //getAddressString: propertyDetailService.getAddressString,
            selectedProperty: selectedProperty,
            customCompSettingsModel: customCompSettingsModel,
            getProfileCustomSettingsForUser: getProfileCustomSettingsForUser,
            saveCompSettings: saveCompSettings,
            customComparablesPopup: customComparablesPopup,
            resultOwner_click: resultOwner_click,
            reportsContainer: reportsContainer,
            togglePropertyReportView: togglePropertyReportView,
            serviceProducts: serviceProducts,
            productServicesProducts: productServicesProducts,
            purchaseableReportsShoppingCart: purchaseableReportsShoppingCart,
            shoppingCartLabel: shoppingCartLabel,
            shoppingCartEnable: shoppingCartEnable,
            walletBalance: walletBalance,
            creditBalance: creditBalance,
            walletEnabled: walletEnabled,
            creditsEnabled: creditsEnabled,
            hasPrice: hasPrice,
            addFundsSuccessMessage: addFundsSuccessMessage,
            formatCurrency: formatCurrency,
            getPriceString: propertyDetailService.getPriceString,
            getRemainingCreditsValue: propertyDetailService.getRemainingCreditsValue,
            getCreditString: propertyDetailService.getCreditString,
            getRemainingCredits: propertyDetailService.getRemainingCredits,
            propertyProfileModel: propertyProfileModel,
            propertyProfileResultStatus: propertyProfileResultStatus,
            propertyProfileProfileId: propertyProfileProfileId,
            propertyProfileProperty: propertyProfileProperty,
            propertyProfileAvailableSections: propertyProfileAvailableSections,
            propertyProfileAvailableReportTypes: propertyProfileAvailableReportTypes,
            getReport: getReport,
            showReports: showReports,
            selectedAPN: selectedAPN,
            validateDateRange: validateDateRange,
            toggleAddToCart: toggleAddToCart,
            showShoppingCart: showShoppingCart,
            purchaseableReportsShoppingCartItems: purchaseableReportsShoppingCartItems,
            createOrder: createOrder,
            reportLoaded: reportLoaded,
            searchBtnText: searchBtnText,
            saveDefaultSetting: saveDefaultSetting,
            customizeYourReport: customizeYourReport,
            placeOrderForThisProperty: placeOrderForThisProperty,
            isCustomizeYourReportPopUp: isCustomizeYourReportPopUp,
            defaultSettings: defaultSettings,
            reportPreparedForModel: reportPreparedForModel,
            ChangeProfileURL: ChangeProfileURL,
            emailPropertyProfileReportModel: emailPropertyProfileReportModel,
            EmailPropertyProfileReportPopUp: EmailPropertyProfileReportPopUp,
            selectedDropdownValue: selectedDropdownValue,
            showProfileLinks: showProfileLinks,
            getPropProfileButtonText: propertySearchCommon.getPropProfileButtonText,
            getSelectedProfileCss: ko.computed(function () { return session.getSelectedProfileCss(); }),
            setProfileSelectedLanguage: propertySearchCommon.setProfileSelectedLanguage,
            profileLinks: profileLinks,
            getProfileLinks: getProfileLinks,
            openReport: propertySearchCommon.openReport,
            setLanguagePreferance: propertySearchCommon.setLanguagePreferance,
            setLanguagePreferanceHosted: propertySearchCommon.setLanguagePreferanceHosted,
            showEmailPropertyProfileReports: showEmailPropertyProfileReports,
            SendEmailPropertyProfileReport: SendEmailPropertyProfileReport,
            recentSearches: recentSearchesList,//ko.observableArray(JSON.parse(localStorage.recentSearchList)), //session.recentSearches,//getRecentSearchList, //session.recentSearches,
            onRecentSearchClick: onRecentSearchClick,
            showRecentSearchBadge: showRecentSearchBadge,
            onRecentSearchHeaderClick: onRecentSearchHeaderClick,
            onMoreClick: onMoreClick,
            onprofileListClick: onprofileListClick,
            getProfileReport: getProfileReport,
            checkLLAAgreement: session.checkLLAAgreement,
            purchasableProdutsShoppingCartList: purchasableProdutsShoppingCartList,
            purchasableProdutsWalletBalance: purchasableProdutsWalletBalance,
            purchasableProdutsCreditBalance: purchasableProdutsCreditBalance,
            purchasableProdutsWalletEnabled: purchasableProdutsWalletEnabled,
            purchasableProdutsCreditsEnabled: purchasableProdutsCreditsEnabled,
            purchasableProdutsSelectedProperty: purchasableProdutsSelectedProperty,
            //purchasableProdutsECommerceId: purchasableProdutsECommerceId,
            purchasableProdutsTax: purchasableProdutsTax,
            selectedPropertyId: selectedPropertyId,
            isParcelEnabled: isParcelEnabled,
            ftniFormModel: ftniFormModel,
            calculateTotal: calculateTotal,
            canDeactivate: canDeactivate,
            compositionComplete: function () {
                session.lastLogonSession();
                session.validateInput();
                initMap();
            },
            goBack: goBack,
            title: 'Purchase Summary',
            paymentModeView: paymentModeView,
            paymentMode: paymentMode,
            paymentModeList: paymentModeList,
            creditCardInformationModel: creditCardInformationModel,
            orderSummaryModel: orderSummaryModel,
            walletModel: walletModel,
            tax: tax,
            subTotal: subTotal,
            totalCredits: totalCredits,
            Total: Total,
            subTotalDisplay: subTotalDisplay,
            totalDisplay: totalDisplay,
            taxDisplay: taxDisplay,
            canPurchaseWithWallet: canPurchaseWithWallet,
            enablePayment: enablePayment,
            reset: reset,
            PlaceOrder: PlaceOrder,
            Cancel: Cancel,
            resetSessionVariables: resetSessionVariables,
            showAddFundsWalletModal: showAddFundsWalletModal,
            CancelAddFunds: CancelAddFunds,
            HideAddFundsSuccessModal: HideAddFundsSuccessModal,
            showCVCModal: showCVCModal,
            HideCVCModal: HideCVCModal,
            AddFundsToWallet: AddFundsToWallet,
            placeOrderProducts: placeOrderProducts,
            amountToAdd: amountToAdd,
            amountToAddString: amountToAddString,
            //commonDialogModel2: commonDialogModel2,
            onCancelOrCloseAddFundsToWallet: onCancelOrCloseAddFundsToWallet,
            session: session,
            APNFormatText: APNFormatText,
            apnModelZipChangeEvent: apnModelZipChangeEvent,
            ownerModelZipChangeEvent: ownerModelZipChangeEvent,
            streetLookupModelZipChangeEvent: streetLookupModelZipChangeEvent,
            showPurchasableProducts: showPurchasableProducts,
            isSearchInProgress: isSearchInProgress,
            closeMultiplePropertyWarning: propertySearchCommon.closeMultiplePropertyWarning,
            ScrollToElement: ScrollToElement,
            handleSearchTextEnter: handleSearchTextEnter,
            radiusChange: radiusChange,
            toggleFullScreen: toggleFullScreen,
            isFullScreen: isFullScreen,
            isBingAvailable: isBingAvailable,
            toggleSplitScreenTitle: toggleSplitScreenTitle,
            activeAdvancedSearchUsed: activeAdvancedSearchUsed,
            toggleCenterSelectedProperty: toggleCenterSelectedProperty,
            isParcelOn: isParcelOn,
            isFindPropertyOn: isFindPropertyOn,
            toggleParcelOverlay: toggleParcelOverlay,
            toggleParcelTitle: toggleParcelTitle,
            toggleFindPropertyTitle: toggleFindPropertyTitle,
            mapLinkClick: mapLinkClick,
            isProductShownForSelectedProperty: isProductShownForSelectedProperty,
            map: map,
            isHighContrastOn: isHighContrastOn,
            toggleHighContrast: toggleHighContrast,
            mobileViewProfileLinksVisible: mobileViewProfileLinksVisible,
            toggleFindProperty: toggleFindProperty,
            isPropertyLatLongAvailable: isPropertyLatLongAvailable,
            getAddressOrOwnerString: getAddressOrOwnerString,
            pendingApprovals: pendingApprovals,
            openAdmin: pendingApprovalService.openAdmin,
        };

        vm.APNErrors = ko.validation.group([vm.apnModel]);
        vm.AmountToAddError = ko.validation.group([vm.amountToAdd]);
        vm.emailPropertyProfileReportModelError = ko.validation.group([vm.emailPropertyProfileReportModel]);

        vm.ownerErrors = ko.validation.group([vm.ownerModel]);

        vm.addressErrors = ko.validation.group([vm.addressModel]);
        vm.streetErrors = ko.validation.group([vm.streetLookupModel]);
        vm.customSettingsErrors = ko.validation.group([vm.customCompSettingsModel]);

        vm.predictiveTextBox.subscribe(function (newVal) {
        });

        vm.ownerModel.state.subscribe(function (state) {
            if (ownerModelZipChangeEvent() === false) {
                ownerfips([]);
                vm.ownerModel.zip("");
            }
            if (state != undefined && state != '') {
                getOwnerCounties(state);
            }
        });

        vm.isSearchInProgress.subscribe(function (value) {

            if (value) {
                session.isBusy(true);
            }
            else {
                session.isBusy(false);
            }


            //console.log('isSearchInProgress ' + value);
        });

        vm.apnModel.state.subscribe(function (state) {
            if (apnModelZipChangeEvent() === false) {
                apnfips([]);
                vm.apnModel.zip("");
            }
            APNFormatText("");
            if (state != undefined && state != '') {
                getApnModelCounties(state);
            }
        });

        vm.apnModel.county.subscribe(function (fips) {
            APNFormatText("");
            if (fips != undefined && fips != '') {
                getApnFormat(fips);
            }
        });

        vm.streetLookupModel.state.subscribe(function (state) {
            if (streetLookupModelZipChangeEvent() === false) {
                streetfips([]);
                streetLookupModel.city("");
                vm.streetLookupModel.zip("");
            }
            if (state != undefined && state != '') {
                getStreetLookupCounties(state);
            }
        });

        function getSvcGetStateDataByZipReqParam(zip, isReseting) {

            if (session.userAddressZip() == zip && session.userZipInfo() != undefined) {
                var data = session.userZipInfo();
                if (data.CountyName != null && data.Fips != null) {
                    // console.log('before getSvcGetStateDataByZipReqParam');
                    if (isReseting != true) {
                        setPropertySearchControlValues(data.StateAbbr, data.City, zip, vm.addressModel.streetNumber(), vm.addressModel.streetName(), vm.addressModel.unitNumber());
                        // console.log('getSvcGetStateDataByZipReqParam completed');
                    }
                    return;
                }
            }


            var svcGetStateDataByZip = {
                successCallback: function (data) {
                    if (data != undefined && data != null && data.HasErrors == false) {
                        if (data.CountyName != null && data.Fips != null) {
                            // console.log('before getSvcGetStateDataByZipReqParam');
                            if (isReseting != true) {
                                setPropertySearchControlValues(data.StateAbbr, data.City, zip, vm.addressModel.streetNumber(), vm.addressModel.streetName(), vm.addressModel.unitNumber());
                                // console.log('getSvcGetStateDataByZipReqParam completed');
                            }
                        }
                        else {
                            alert.error("Invalid Zip.");
                            addressModel.zip('');
                            addressModel.city('');
                            addressModel.state('');
                        }
                    }
                    else
                        session.zipCodeServiceDown();
                },
                failCallback: Failed,
                completeCallback: function () {
                    isSearchable(true);
                }
            };
            return svcGetStateDataByZip;
        }

        initAddressSearch();



        function initAddressSearch() {
            vm.addressModel.zip.subscribe(function (zip) {
                //console.log('vm.addressModel.zip');
                if (zip != undefined && zip != '' && vm.addressModel.isResetting == false) {
                    var reqParam = getSvcGetStateDataByZipReqParam(zip, vm.addressModel.isResetting);
                    if (reqParam != undefined)
                        addressSearchService.getStateAndCityByZip(zip, reqParam.successCallback, reqParam.failCallback, reqParam.completeCallback);
                }
                else {

                }
                //console.log('vm.addressModel.zip completed');
            });
        }

        vm.ownerModel.zip.subscribe(function (zip) {
            if (vm.ownerModel.event != null && vm.ownerModel.event == 'countyUIEvent') {
                vm.ownerModel.event = undefined;
                return;
            }

            if (zip != undefined && zip != '') {
                ownerModelZipChangeEvent(true);
                ownerfips([]);
                var url = config.getZipInfoUrl;
                isSearchable(false);

                if (session.userAddressZip() == zip && session.userZipInfo() != undefined) {

                    if (session.userZipInfo().CountyName != null && session.userZipInfo().Fips != null) {
                        ownerfips(session.userZipInfo());

                        if (ownerfips().Fips != null) {
                            ownerfipsNo(ownerfips().Fips);
                        }
                        ownerModel.state(session.userZipInfo().StateAbbr);
                        ownerModel.state.valueHasMutated();
                        //getOwnerCounties(session.userZipInfo().StateAbbr);
                        isSearchable(true);
                        ownerModelZipChangeEvent(false);
                        return;
                    }

                }

                Q.when(https.get(url, {
                    zipCode: zip,
                    format: 'json'
                })
                    .then(function (data) {
                        if (data != undefined && data != null && data.HasErrors == false) {
                            if (data.CountyName != null && data.Fips != null) {
                                ownerfips(data);

                                if (ownerfips().Fips != null) {
                                    ownerfipsNo(ownerfips().Fips);
                                }
                                ownerModel.state(data.StateAbbr);
                                ownerModel.state.valueHasMutated();
                                //getOwnerCounties(data.StateAbbr);

                            }
                            else {
                                alert.error("Invalid Zip.");
                                ownerModel.zip('');
                                ownerModel.state('');
                                ownerModel.county('');
                            }
                        }
                        else
                            session.zipCodeServiceDown();

                    }).always(function () {
                        ownerModelZipChangeEvent(false);
                        isSearchable(true);
                    })

                    .fail(Failed));
            }
            else {
                ownerModelZipChangeEvent(false);
            }
        });

        vm.apnModel.zip.subscribe(function (zip) {
            if (vm.apnModel.event != null && vm.apnModel.event == 'countyUIEvent') {
                vm.apnModel.event = undefined;
                return;
            }

            //APNFormatText("");
            if (zip != undefined && zip != '') {
                apnModelZipChangeEvent(true);
                apnfips([]);
                var url = config.getZipInfoUrl;
                isSearchable(false);


                if (session.userAddressZip() == zip && session.userZipInfo() != undefined) {

                    if (session.userZipInfo().CountyName != null && session.userZipInfo().Fips != null) {
                        apnfips(session.userZipInfo());

                        if (apnfips().Fips != null) {
                            apnfipsNo = apnfips().Fips;
                        }
                        apnModel.state(session.userZipInfo().StateAbbr);
                        apnModel.state.valueHasMutated();
                        //  getApnModelCounties(session.userZipInfo().StateAbbr);
                        isSearchable(true);
                        apnModelZipChangeEvent(false);
                        return;
                    }

                }

                Q.when(https.get(url, {
                    zipCode: zip,
                    format: 'json'
                })
                    .then(function (data) {
                        if (data != undefined && data != null && data.HasErrors == false) {
                            if (data.CountyName != null && data.Fips != null) {
                                apnfips(data);

                                if (apnfips().Fips != null) {
                                    apnfipsNo = apnfips().Fips;
                                }

                                apnModel.state(data.StateAbbr);
                                apnModel.state.valueHasMutated();
                                //    getApnModelCounties(data.StateAbbr);

                            }
                            else {
                                alert.error("Invalid Zip.");
                                apnModel.zip('');
                                apnModel.state('');
                                apnModel.county('');
                            }
                        }
                        else
                            session.zipCodeServiceDown();

                    }).always(function () {
                        isSearchable(true);
                        apnModelZipChangeEvent(false);
                    })

                    .fail(Failed));
            }
            else {
                apnModelZipChangeEvent(false);
            }
        });

        vm.streetLookupModel.zip.subscribe(function (zip) {
            if (vm.streetLookupModel.event != null && vm.streetLookupModel.event == 'countyUIEvent') {
                vm.streetLookupModel.event = undefined;
                return;
            }

            if (zip != undefined && zip != '') {
                streetLookupModelZipChangeEvent(true);
                streetfips([]);

                var url = config.getZipInfoUrl;
                isSearchable(false);

                if (session.userAddressZip() == zip && session.userZipInfo() != undefined) {

                    if (session.userZipInfo().CountyName != null && session.userZipInfo().Fips != null) {
                        streetfips(session.userZipInfo());

                        if (streetfips().Fips != null) {
                            streetfipsNo = streetfips().Fips;
                        }
                        streetLookupModel.state(session.userZipInfo().StateAbbr);
                        streetLookupModel.state.valueHasMutated();
                        streetLookupModel.city(session.userZipInfo().City);
                        //getStreetLookupCounties(session.userZipInfo().StateAbbr);
                        isSearchable(true);
                        streetLookupModelZipChangeEvent(false);
                        return;
                    }

                }

                Q.when(https.get(url, {
                    zipCode: zip,
                    format: 'json'
                })
                    .then(function (data) {
                        if (data != undefined && data != null && data.HasErrors == false) {
                            if (data.CountyName != null && data.Fips != null) {
                                streetfips(data);
                                if (streetfips().Fips != null) {
                                    streetfipsNo = streetfips().Fips;
                                }
                                streetLookupModel.state(data.StateAbbr);
                                streetLookupModel.state.valueHasMutated();
                                streetLookupModel.city(data.City);
                                //  getStreetLookupCounties(data.StateAbbr);

                            }
                            else {
                                alert.error("Invalid Zip.");
                                streetLookupModel.zip('');
                                streetLookupModel.state('');
                                streetLookupModel.city('');
                                streetLookupModel.county('');
                            }
                        }
                        else
                            session.zipCodeServiceDown();

                    }).always(function () {
                        streetLookupModelZipChangeEvent(false);
                        isSearchable(true);
                    }).fail(Failed));
            }
            else
                streetLookupModelZipChangeEvent(false);
        });

        function APNSearchButtonClick() {
            if (!session.llaAccepted()) {
                session.showLLAAgreement();
                return;
            }

            if (APNSearchAPNNumber() == undefined || APNSearchCounty() == undefined || APNSearchAPNNumber() == null || APNSearchCounty() == null || APNSearchAPNNumber() == "" || APNSearchCounty() == "") {
                commonDialogModel.show("Find Your Property", "Please provide the APN Number & County to search.");
                return;
            }

            if (session.allCountiesWithFips() != undefined && session.allCountiesWithFips() != null) {
                var countyFipsObj = null;
                for (i = 0; i < session.allCountiesWithFips().length; i++) {
                    if (session.allCountiesWithFips()[i].label.toLowerCase() === APNSearchCounty().toLowerCase()) {
                        countyFipsObj = session.allCountiesWithFips()[i];
                        break;
                    }
                }

                if (countyFipsObj == undefined || countyFipsObj == null) {
                    commonDialogModel.show("Find Your Property", "County & State Abbreviation doesn't exist.");
                    return;
                }

                var tempArray = APNSearchCounty().split(",");
                var State = tempArray[1].trim();

                cleartMapResults();
                isMarkable = true;

                //console.log("APNSearchButtonClick function called");
                var apnModelQS = new model.ApnSearch();
                isSearchInProgress(true);
                isFindYourPropertyEnabled(false);

                apnModelQS.reset();
                apnModelQS.zip("");
                apnModelQS.county(countyFipsObj.value);
                apnModelQS.apnNumber(APNSearchAPNNumber());
                apnModelQS.state(State);
                activeAdvancedSearchUsed('Address');
                apnSearch.addressSearch(searchResult, apnModelQS, isSearchable);
            }
            else {
                return;
            }
        }

        function toggleCenterSelectedProperty() {
            if (session.isSelectedPropertyNeedToBeCentered()) {
                session.isSelectedPropertyNeedToBeCentered(false);
            }
            else {
                session.isSelectedPropertyNeedToBeCentered(true);
            }
        }

        function toggleParcelOverlay() {
            isParcelOn(!isParcelOn());

        }

        function toggleFindProperty() {
            isFindPropertyOn(!isFindPropertyOn());
        }

        function toggleHighContrast() {
            //   isHighContrastOn(!isHighContrastOn());
        }

        function toggleFullScreen() {
            if (isFullScreen()) {
                $('#dvPanoContainer').removeClass().addClass('col-lg-5 col-md-5 col-sm-12 col-12 order-1 panoContainer');
                $('#dvmapContainer').removeClass().addClass('order-2 col-lg-7 col-sm-12 col-xs-12 col-md-7 px-0');
                isFullScreen(false);
                toggleSplitScreenTitle(' Toggle Full Screen');
            }
            else {
                $('#dvmapContainer').removeClass().addClass('col-12  px-0');
                $('#dvPanoContainer').removeClass().addClass('invisible');
                isFullScreen(true);
                toggleSplitScreenTitle(' Toggle Split Screen');

            }
        }


        function clearQuickSearch() {
            $('#pac-input').val('').trigger("change");
            $('#pac-input-APN').val('').trigger("change");
            $('#pac-input-County').val('').trigger("change");

            if (searchType() == "Address") {
                $('#pac-input').trigger("focus");
            }
            else {
                $('#pac-input-APN').trigger("focus");
            }

            propertySearchCommon.searchDetails.searchContextChanged(Date());
        }

        function reportLoaded() {
            //console.log('reportLoaded');
            //$('#dvAvailableReports').slimScroll({
            //    height: '180px',
            //});
        }

        function showBasicSearch() {
            //showMailDialog(true);
            isFindPropertyOn(true);
            if (!$('#RadialMenu').hasClass('opened')) { $('.FindYourProperty').show(); }

            isSearchInProgress(false);
            cleartMapResults();

            //initMapAutocomplete();
            isFindYourPropertyEnabled(true);
            isPredictiveInput(true);
            isStreetView(false);
            showReports(false);
            minimizeAdvanceSearch();
            $(window).scrollTop(0);
        }

        function getPropertyDetailsFromMapLink(selectedAPN) {
            ko.utils.arrayFilter(propertyListing(), function (item) {
                if (item.APN == selectedAPN) {
                    loadPropertyDetail(item);
                }
            });
            return false;
        }

        function formatCurrency(value) {
            return propertyDetailService.formatCurrency(value());
        }

        function openPopupMessage() {
            $('#mdPopupMessage').modal('show');
        }


        function resultOwner_click(selectedItem, event) {
            addInfoBoxForProperty(selectedItem);
            //loadPropertyDetail(selectedItem);
            //showReports(false);
            //showInfoBox(selectedItem);
        }

        function showInfoBox(selectedItem) {
            for (var i = infoBoxRef.length - 1; i >= 0; i--) {
                if (infoBoxRef[i]) {
                    infoBoxRef[i].setOptions({
                        visible: false
                    });

                    currentLocation = infoBoxRef[i].getLocation();
                    var selectedAPN = infoBoxRef[i].apn;
                    if (selectedItem.APN == selectedAPN) {
                        //	                if (selectedItem.Latitude == currentLocation.latitude && selectedItem.Longitude == currentLocation.longitude) {
                        infoBoxRef[i].setOptions({
                            description: buildInfoBoxContent(selectedItem),
                            visible: true
                        });

                    }
                }
            }
        }

        function hideInfoBoxes() {
            for (var i = infoBoxRef.length - 1; i >= 0; i--) {
                if (infoBoxRef[i]) {
                    infoBoxRef[i].setOptions({
                        visible: false
                    });
                }
            }
        }

        var MapWithSplashScreen = function (mapDiv, mapOptions) {
            var self = this;
            this.onLoad = null;

            if (!mapDiv || !mapOptions) {
                return;
            }

            //Crate a splash screen. For simplicity, using a gray div.
            var ss = document.createElement('div');
            ss.style.width = '100%';
            ss.style.height = '100%';
            ss.style.position = 'absolute';
            ss.style.top = '0px';
            ss.style.left = '0px';
            ss.style.zIndex = 10000;
            ss.style.backgroundColor = 'gray';

            mapDiv.appendChild(ss);

            //Create a temporary custom overlay.
            var SplashScreenOverlay = function () {
            }
            //Set prototype to sub-class CustomOverlay
            SplashScreenOverlay.prototype = new Microsoft.Maps.CustomOverlay();

            SplashScreenOverlay.prototype.onAdd = function () {
                //Need to add something to the DOM so we can track when it gets added to the map.
                this.setHtmlElement(document.createElement('div'));
            };

            //Implement the onLoad method to perform custom operations of rendering the DOM element
            SplashScreenOverlay.prototype.onLoad = function () {
                //Map should be fully loaded at this point, remove spalsh screen.				
                mapDiv.removeChild(ss);

                if (self.onLoad) {
                    self.onLoad(map);
                }

                this.getMap().layers.remove(this);
            };

            var map = new Microsoft.Maps.Map(mapDiv, mapOptions);

            map.layers.insert(new SplashScreenOverlay());
        };

        function loadPropertyDetail(selectedItem) {

            isPropertyLatLongAvailable(selectedItem.Latitude != "0" && selectedItem.Longitude != "0");



            //console.log('selectedItem');
            //console.log(selectedItem);
            isProductShownForSelectedProperty(false);
            propertyProfileModel.availableReportTypes([]);
            pushItemToRecentSearches(selectedItem);
            selectedAPN(selectedItem.APN);
            propertyDetailResponse(undefined);
            reportsContainer('propertySearchModule/_showReportButtonContainer.html');
            selectedProperty(selectedItem);
            getProfileLinks();


            if (isBingAvailable()) {
                if (!isPropertyLatLongAvailable()) {
                    clearMarkers();
                    if (selectedItem.GeoQuery != null && selectedItem.GeoQuery != "") {
                        geocodeQuery(selectedItem.GeoQuery);

                        if (selectedItem.IsAddressLocatable == false) {
                            map.setView({
                                zoom: 4
                            });
                        }

                        if (isLoadCart()) {
                            togglePropertyReportView();
                            showReports(false);
                        }
                        else {

                            //if (showReports()) {
                            app.showMessage("<p>Sorry, we were unable to locate the property on a map.</p> <p>Good news, we can provide you with a Property Profile. Would you like to continue?</p>", "Property Not located", ['Yes - Get the Property Profile', 'Cancel'], true).then(
                                function (selectedOption) {
                                    if (selectedOption != 'Cancel') {
                                        getProfileReport();
                                    }
                                    else {

                                    }
                                });
                        }


                        //}
                        //else {
                        //    app.showMessage("<p>Sorry, we were unable to locate the property on a map.</p>", "Property Not located", ['OK'], true);
                        //}
                    }
                    else {
                        cleartMapResults()
                        handleNoResult();
                        return;
                    }
                }
                else {
                    setStreetView(selectedItem.Latitude, selectedItem.Longitude);
                    var location = new Microsoft.Maps.Location(selectedItem.Latitude, selectedItem.Longitude);
                    if (isSearchByClick()) {
                        isSearchByClick(false);
                        if (session.isSelectedPropertyNeedToBeCentered()) {
                            map.setView({
                                center: location,
                                zoom: 18
                            });
                            //map.setView({ bounds: location.bestView });
                        }
                    }
                    else {
                        map.setView({
                            center: location,
                            zoom: 18
                        });
                    }

                    showInfoBox(selectedItem);
                    map.entities.push(new Microsoft.Maps.Pushpin(location));

                }
            }



            var currentLocation = null;
            purchaseableReportsShoppingCartItems([]);
            purchaseableReportsShoppingCart([]);
            shoppingCartEnable(false);
            shoppingCartLabel("Shopping Cart");

        }

        function pushItemToRecentSearches(item) {
            var removedItem = session.recentSearches.remove(function (data) {
                return data.APN == item.APN;
            });
            if (removedItem.length <= 0 && session.recentSearches().length > 4) {
                session.recentSearches.pop();
            }
            session.recentSearches.unshift(item);
            session.setRecentSearchesToLocalStorage(session.recentSearches());

            var test = session.getRecentSearchesFromLocalStorage();

            recentSearchesList(test);
            //console.log(recentSearchesList());
        }

        function handlePropertyDetailView() {
            purchaseableReportsShoppingCart([]);
            shoppingCartLabel("Shopping Cart");
            shoppingCartEnable(false);
        }


        function togglePropertyReportView() {

            vm.closeMultiplePropertyWarning();
            showReports(true);
            // showMailDialog(false);
            return getPropertyReports();
        }



        function getPropertyReports() {
            return getPropertyDetailByObject(selectedProperty).done(function (id) {
                propertyDetailService.getPropertyDetailsById(id).done(function (result) {
                    //propertyDetailResponse(result);
                    isProductShownForSelectedProperty(true);
                    return loadReportResults(result);
                    //response.hasGetMethodError(result.HasErrors);
                });
            });
        }

        function loadReportResults(result) {
            //console.log('loadReportResults');

            var converted = propertyDetailService.loadPurchaseableReportsToKOArray(result.ProductServicesProducts);
            productServicesProducts(converted);
            for (var i = 0; i < converted.length; i++) {
                var priceValue = converted[i].Price();
                if (priceValue.indexOf('$') > -1)
                    priceValue = priceValue.replace('$', '');
                //if(converted[i].Credits > 0 && )
                //{

                //}
                if (priceValue > 0) {
                    hasPrice(true);
                    break;
                }
            }
            //console.log(productServicesProducts());
            serviceProducts(result.ServiceProducts);
            //console.log(serviceProducts());
            walletBalance(result.WalletBalance);
            creditBalance(result.CreditBalance);
            walletEnabled(result.WalletEnabled);
            creditsEnabled(result.CreditsEnabled);

            //Required for Payment
            purchasableProdutsWalletBalance(result.WalletBalance);
            if (purchasableProdutsWalletBalance() === 0) {
                paymentModeList.remove(function (remove) {
                    return remove.key == 'Wallet';
                });
            }
            purchasableProdutsCreditBalance(result.CreditBalance);
            purchasableProdutsWalletEnabled(result.WalletEnabled);
            purchasableProdutsCreditsEnabled(result.CreditsEnabled);
            purchasableProdutsTax(result.Tax);
            //session.selectedPropertyId(result.Property.Id);
            selectedPropertyId(result.Property.Id);

            reportsContainer('propertySearchModule/_availableReports.html');
            reportLoaded();
            showReports(false);
            if (isLoadCart()) {
                setTimeout(function () {
                    isLoadCart(false);
                    //console.log(savedCart());
                    app.showMessage(savedCart().Message, savedCart().Title).then(function (dialogResult) {
                        var selectedProds = savedCart().Products;
                        for (var i = 0; i < selectedProds.length; i++) {
                            var prod = productServicesProducts().filter(function (p) {
                                return p.Name() === selectedProds[i].Name;
                            })[0];
                            if (prod) {
                                prod.UseCredits(selectedProds[i].UseCredit);
                                $('#' + prod.AddBtnId()).trigger('click');
                            }
                        }
                        $('#tbAvailableReports li:last-child a').tab('show');
                    });
                }, 20);
            }

            //setProfileSelectedLanguage(result.ProfileLanguagePreference);
            return true;
        }


        function getPropertyDetail(selectedItem, event) {
            getPropertyDetailByObject(selectedItem);
        }

        function getPropertyDetailByObject(selectedItem) {
            var url = config.addToMyPropertiesUrl;
            var property = {
                "APN": selectedItem().APN,
                "Address": selectedItem().Address,
                "AssessmentYear": selectedItem().AssessmentYear,
                "BorrowAmount": selectedItem().BorrowAmount,
                "City": selectedItem().City,
                "County": selectedItem().County,
                "FIPS": selectedItem().FIPS,
                "LatestSalePrice": selectedItem().LatestSalePrice,
                "LatestSaleRecordingDate": selectedItem().LatestSaleRecordingDate,
                "Latitude": selectedItem().Latitude,
                "LoanAmount": selectedItem().LoanAmount,
                "Longitude": selectedItem().Longitude,
                "Owner": selectedItem().Owner,
                "OwnerMailAddress": selectedItem().OwnerMailAddress,
                "OwnerMailCity": selectedItem().OwnerMailCity,
                "OwnerMailState": selectedItem().OwnerMailState,
                "OwnerMailUnit": selectedItem().OwnerMailUnit,
                "OwnerMailUnitType": selectedItem().OwnerMailUnitType,
                "OwnerMailZip": selectedItem().OwnerMailZip,
                "OwnerMailZip4": selectedItem().OwnerMailZip4,
                "State": selectedItem().State,
                "TaxDelinquency": selectedItem().TaxDelinquency,
                "TaxRateCodeArea": selectedItem().TaxRateCodeArea,
                "TotalAssessedValue": selectedItem().TotalAssessedValue,
                "TotalMarketValue": selectedItem().TotalMarketValue,
                "UnitNumber": selectedItem().UnitNumber,
                "UnitType": selectedItem().UnitType,
                "UseCode": selectedItem().UseCode,
                "UseCodeDescription": selectedItem().UseCodeDescription,
                "ZIP": selectedItem().ZIP,
                "ZIP4": selectedItem().ZIP4
            };
            url = $.getBasePath() + "api/Property/AddToMyProperties?userId=" + session.userId;
            return https.post(url, property)
                .then(function (data) {
                    if (data) {
                        //console.log(data);

                        //getPropertyDetail(session.userId, session.defaultOpertionId(), data.Id).done(function (result) {
                        //router.navigate("#/propertyDetail/" + data.Id);
                        return data.Id;
                        //});
                    }

                })
                .fail(Failed);
        }

        function getAddressStringNotZip(propertyData) {
            var addressString = getAddressString(propertyData);
            if (addressString == propertyData.ZIP || addressString == "") {
                addressString = propertyData.APN;
            }
            return addressString;
        }

        function getAddressString(propertyData) {
            //console.log('getAddressString');
            //console.log(propertyData);

            var addressString = '';

            if (propertyData.Address != null && propertyData.Address.length > 0)
                addressString = propertyData.Address.trim() + ' ';

            if (propertyData.UnitType != null && propertyData.UnitType.length > 0)
                addressString = addressString + propertyData.UnitType.trim() + ' ';

            if (propertyData.UnitNumber != null && propertyData.UnitNumber.length > 0)
                addressString = addressString + propertyData.UnitNumber.trim();

            addressString = addressString.trim() + ', ';

            if (propertyData.City != null && propertyData.City.length > 0)
                addressString = addressString + propertyData.City.trim() + ', ';

            if (propertyData.County != null && propertyData.County != null && propertyData.County.length > 0 && propertyData.City.toUpperCase() != propertyData.County.toUpperCase())
                addressString = addressString + propertyData.County.toUpperCase().trim() + ', ';

            if (propertyData.State != null && propertyData.State.length > 0)
                addressString = addressString + propertyData.State.trim() + ' ';

            if (propertyData.ZIP != null && propertyData.ZIP.length > 0)
                addressString = addressString + propertyData.ZIP.trim();

            if (propertyData.ZIP4 != null && propertyData.ZIP4.length > 0) {
                addressString = addressString + '-' + propertyData.ZIP4.trim()
            }
            return addressString;
        }


        function getAddressOrOwnerString(propertyData) {
            //console.log('getAddressString');
            //console.log(propertyData);

            var addressString = '';

            if (propertyData.Address != null && propertyData.Address.length > 0)
                addressString = propertyData.Address.trim() + ' ';

            if (propertyData.UnitType != null && propertyData.UnitType.length > 0)
                addressString = addressString + propertyData.UnitType.trim() + ' ';

            if (propertyData.UnitNumber != null && propertyData.UnitNumber.length > 0)
                addressString = addressString + propertyData.UnitNumber.trim();

            if (addressString.length == 0) {
                addressString = propertyData.Owner;
            }

            addressString = addressString.trim() + ', ';

            if (propertyData.City != null && propertyData.City.length > 0)
                addressString = addressString + propertyData.City.trim() + ', ';

            if (propertyData.County != null && propertyData.County != null && propertyData.County.length > 0 && propertyData.City.toUpperCase() != propertyData.County.toUpperCase())
                addressString = addressString + propertyData.County.toUpperCase().trim() + ', ';

            if (propertyData.State != null && propertyData.State.length > 0)
                addressString = addressString + propertyData.State.trim() + ' ';

            if (propertyData.ZIP != null && propertyData.ZIP.length > 0)
                addressString = addressString + propertyData.ZIP.trim();

            if (propertyData.ZIP4 != null && propertyData.ZIP4.length > 0) {
                addressString = addressString + '-' + propertyData.ZIP4.trim()
            }
            return addressString;
        }

        //function getPropertyDetail(userId,operationId,propertyId) {
        //    var url = config.getPropertyDetailUrl;
        //   return https.get(url, {
        //        userId: userId,
        //        operationId:operationId,
        //        propertyId:propertyId,
        //        format: 'json'
        //    })

        //}

        function GetStatesList() {
            var url = config.stateUrl;
            stateList([]);

            if (session.stateList() != undefined) {
                stateList(session.stateList());
                return;
            }

            Q.when(https.get(url, {
                stateCode: true, format: 'json'
            })
                .then(function (data) {
                    stateList(data);
                }).always(function () {
                    resetAdvanceSearch();
                })
                .fail(Failed))
                ;
        }

        function Failed(error) {
            if (error.responseJSON && error.responseJSON.ExceptionMessage && error.responseJSON.ExceptionMessage.indexOf('Bing') > -1) {
                alert.warning('We have experienced a minor technical issue. Please use the Advanced Search option.');
                showAdvanceSearch();
            }
            else {
                isSearchInProgress(false);
                alert.error(error.statusText);
            }
        }


        function getOwnerCounties(state) {
            var url = config.countyUrl;
            isSearchable(false);

            if (session.userAddressStateAbbr() == state && session.userCountiesList() != undefined) {

                ko.utils.arrayFilter(session.userCountiesList(), function (item) {
                    if (item.Value == ownerfips().Fips) {
                        //console.log('Fips Found ' + ownerfips().Fips);

                        ownerCountyList(session.userCountiesList());
                        if (ownerfips().Fips != undefined && ownerfips().Fips != '') {
                            ownerModel.county(ownerfips().Fips);
                        }
                        isSearchable(true);
                        return;

                    }
                });


            }

            Q.when(https.get(url, {
                stateAbbr: state,
                format: 'json'
            })
                .then(function (data) {
                    ownerCountyList(data);
                    if (ownerfips().Fips != undefined && ownerfips().Fips != '') {
                        ownerModel.county(ownerfips().Fips);
                    }
                }).always(function () {
                    isSearchable(true);
                })
                .fail(Failed)
            );
        }

        function getApnModelCounties(state) {
            var url = config.countyUrl;
            isSearchable(false);

            if (session.userAddressStateAbbr() == state && session.userCountiesList() != undefined) {
                apnCountyList(session.userCountiesList());
                if (apnfips().Fips != undefined && apnfips().Fips != '') {
                    apnModel.county(apnfips().Fips);
                }
                isSearchable(true);
                return;
            }

            Q.when(https.get(url, {
                stateAbbr: state,
                format: 'json'
            })
                .then(function (data) {
                    apnCountyList(data);
                    if (apnfips().Fips != undefined && apnfips().Fips != '') {
                        apnModel.county(apnfips().Fips);
                    }
                }).always(function () {
                    isSearchable(true);
                })
                .fail(Failed)
            );
        }


        function getApnFormat(fips) {
            if (!session.llaAccepted()) {
                session.showLLAAgreement();
                return;
            }
            var url = config.getAPNFormatUrl;
            isSearchable(false);

            //if (session.userFIPS() == fips && session.userAPNFormat != undefined) {
            //    APNFormatText(session.userAPNFormat);
            //    isSearchable(true);
            //    return;
            //}

            return Q.when(https.get(url, {
                FIPS: fips,
                Zip: apnModel.zip(),
                format: 'json'
            })
                .then(function (data) {
                    if (data != undefined && data != null) {
                        APNFormatText(data);
                    }
                }).always(function () {
                    isSearchable(true);
                })
                .fail(Failed)
            );
        }

        function getStreetLookupCounties(state) {
            var url = config.countyUrl;
            isSearchable(false);
            session.isAjaxPost(true);

            if (session.userAddressStateAbbr() == state && session.userCountiesList() != undefined) {
                streetCountyList(session.userCountiesList());
                if (streetfips().Fips != undefined && streetfips().Fips != '') {
                    streetLookupModel.county(streetfips().Fips);
                }
                isSearchable(true);
                return;
            }

            Q.when(https.get(url, {
                stateAbbr: state,
                format: 'json'
            })
                .then(function (data) {
                    streetCountyList(data);
                    if (streetfips().Fips != undefined && streetfips().Fips != '') {
                        streetLookupModel.county(streetfips().Fips);
                    }
                }).always(function () {
                    isSearchable(true);
                    session.isAjaxPost(false);
                })
                .fail(Failed)
            );
        }


        function searchPropertyByAdress() {
            activeAdvancedSearchUsed('Address');
            if (!session.llaAccepted()) {
                session.showLLAAgreement();
                return;
            }

            if (vm.addressErrors().length > 0) {
                vm.addressErrors.showAllMessages();
                return;
            }

            isQuickSearchResult(false);
            isSearchable(false);
            isMarkable = true;
            searchResult(undefined);
            var addressSearchResult = ko.observable(undefined);
            addressSearch.addressSearch(addressSearchResult, addressModel, isSearchable).done(function () {
                handleAdvanceSearchResult(addressSearchResult());
            });
        }

        function searchPropertyByAPN() {
            activeAdvancedSearchUsed('APN Number');
            if (!session.llaAccepted()) {
                session.showLLAAgreement();
                return;
            }

            if (vm.APNErrors().length > 0) {
                vm.APNErrors.showAllMessages();
                return;
            }
            isQuickSearchResult(false);
            isSearchable(false);
            //console.log('SearchPropertyByAPN called');
            //console.log(apnModel);
            searchResult(undefined);
            var APNSearchResult = ko.observable(undefined);
            apnSearch.addressSearch(APNSearchResult, apnModel, isSearchable).done(function () {
                handleAdvanceSearchResult(APNSearchResult());
            });
        }

        function searchPropertyByOwner() {
            activeAdvancedSearchUsed('Owner');
            if (!session.llaAccepted()) {
                session.showLLAAgreement();
                return;
            }

            if (vm.ownerErrors().length > 0) {
                vm.ownerErrors.showAllMessages();
                return;
            }
            isQuickSearchResult(false);
            isSearchable(false);
            //  ownerSearch.addressSearch(searchResult, ownerModel, isSearchable);
            searchResult(undefined);
            var OwnerSearchResult = ko.observable(undefined);
            ownerSearch.addressSearch(OwnerSearchResult, ownerModel, isSearchable).done(function () {
                handleAdvanceSearchResult(OwnerSearchResult());
            });
        }

        function searchPropertyByStreet() {
            activeAdvancedSearchUsed('Street Look-Up');
            if (!session.llaAccepted()) {
                session.showLLAAgreement();
                return;
            }

            if (vm.streetErrors().length > 0) {
                vm.streetErrors.showAllMessages();
                return;
            }
            isQuickSearchResult(false);
            isSearchable(false);
            // searchResult([]);
            //console.log('searchPropertyByStreet called');
            //  streetSearch.addressSearch(searchResult, streetLookupModel, isSearchable);
            searchResult(undefined);
            var StreetSearchResult = ko.observable(undefined);
            streetSearch.addressSearch(StreetSearchResult, streetLookupModel, isSearchable).done(function () {
                handleAdvanceSearchResult(StreetSearchResult());
            });
        }

        function handleAdvanceSearchResult(result) {
            isMarkable = true;
            cleartMapResults();
            searchResult(result);
        }

        function setPropertySearchControlValues(state, city, zip, streetNumber, streetName, unitNumber) {
            if (state != undefined && zip != "" && zip != undefined && state != '') {
                vm.addressModel.reset(state, city, zip, streetNumber, streetName, unitNumber);

                vm.ownerModel.zip('');
                vm.apnModel.zip('');
                vm.streetLookupModel.zip('');
                vm.streetLookupModel.city('');

                vm.ownerModel.zip(session.userAddressZip());
                vm.apnModel.zip(session.userAddressZip());
                vm.streetLookupModel.zip(session.userAddressZip());
                vm.streetLookupModel.city(session.userAddressCity());
            }
        };



        function activate(isAdvanceSearch, searchId, loadUser) {

            //console.log('Bing Addres search activate:', isAdvanceSearch);
            session.isBusy(true);
            searchType('Address');
            activeAdvancedSearchUsed('Address');
            APNSearchAPNNumber('');
            APNSearchCounty('');
            clearQuickSearch();
            session.showTopSearchControl(false);
            //propertyListing([]);
            cleartMapResults();
            isSearchByClick(false);
            isParcelOn(true);
            isFindPropertyOn(true);
            toggleSplitScreenTitle(' Toggle Full Screen');
            ///////////////BKFS
            // showMailDialog(false);
            isFullScreen(false);
            currentUserFullAddress = null;
            isSearchInProgress(false);
            isStreetView(false);
            isParcelEnabled(false);
            isParcelEnabled(session.isParcelOverlayEnabled());
            isLatLongSearchRequest(false);
            showReports(false);

            

            currentAPNZip("");
            currentAPNFips("");

            shoppingCartLabel("Shopping Cart");
            isFindYourPropertyEnabled(true);
            vm.isBasicSearchNoResult(false);
            isQuickSearchNoResultError(false);
            //dtResults = $('#tblPropertyResults').DataTable();
            GetStatesList();
            //  setDMKey();

            session.getIsGlobalLoginMessageEnabled().done(function (result) {
                session.isGlobalLoginMessageEnabled(result);
            });
            

            if (isAdvanceSearch == 'true') {
                isAdvanceSearchFromAddressSearch(false);
                showAdvanceSearch();
                session.showTopSearchControl(true);
            }
            else {
                isAdvanceSearchFromAddressSearch(true);
                isAdvanceSearchEnabled(false);
            }

            isPredictiveInput(true);
            defaultSettings(true);

            if (loadUser === true) {
                markCurrentUserAddress();
            }

            handleAfterPaymentResponse();
            pendingApprovals(session.pendingApprovals());

            //if (window.innerWidth >= 768)
            //    session.isSideNavCollpased(true);
            //else
            //    session.isSideNavCollpased(false);

            //searchResult([]);
            //console.log('activate called');
            //console.log('isAdvanceSearchFromAddressSearch ' + isAdvanceSearchFromAddressSearch());
            setPropertySearchControlValues(session.userAddressStateAbbr(), session.userAddressCity(), session.userAddressZip());
            if (session.showPurchasableProducts() == undefined) {
                var showReportsUrl = config.showPurchasableProductsUrl;

                return https.get(showReportsUrl).done(function (data) {
                    session.showPurchasableProducts(data);
                    showPurchasableProducts(data);
                })
                    .fail(function () {
                        //alert('Error getting property detail');
                    });
            }
            else {
                showPurchasableProducts(session.showPurchasableProducts());
            }
            session.isBusy(false);
        }


        function initMap() {
            $('#pac-input').val('');
            isSearchable(true);
            isMarkable = false;
            isLatLongAvailable(false);
            input = document.getElementById('pac-input');
            currentLatitude(33.6694649);
            currentLongitude(-117.8231107);
            //radiusSearch.refreshRangeSlider();
            propertySearchCommon.searchDetails.searchContextChanged(Date());




            //console.log('initMap called');

            //$('.collapse').on('shown.bs.collapse', function () {
            //    $('.panel-collapse').not(this).removeClass("show");
            //    $(".indicator").removeClass("fa-chevron-up").addClass("fa-chevron-down");
            //    $(this).parent().find(".fa-chevron-down").removeClass("fa-chevron-down").addClass("fa-chevron-up");
            //}).on('hidden.bs.collapse', function () {
            //    $(this).parent().find(".fa-chevron-up").removeClass("fa-chevron-up").addClass("fa-chevron-down");
            //});

            //$('#pac-input-County').autocomplete({
            //    delay: 0,
            //    minLength: 3,
            //    source: session.allCountiesWithFips,
            //    onSelect: function (suggestion) {
            //        alert('You selected: ' + suggestion.Key + ', ' + suggestion.Value);
            //    } 
            //});




            AfterLoad();
            //drawMap();

            if (session.bingMapAPIKey().length == 0) {
                session.getBingMapAPIKey().done(function (result) {
                    session.setMapBaseConfigs(result);
                    drawMap();
                    //setTimeout(drawMap, 2);
                });
            }
            else {
                drawMap();
                //setTimeout(drawMap, 2);
            }

            setPropertySearchControlValues(session.userAddressStateAbbr(), session.userAddressCity(), session.userAddressZip());

            var test = session.getRecentSearchesFromLocalStorage();
            if (test != null && test != undefined) {
                recentSearchesList(test);
                session.recentSearches(test);
            }
            else {
                recentSearchesList([]);
                session.recentSearches([]);
            }

            if (isAdvanceSearchEnabled()) {
                expandAddressSearch();
                if (session.userAddressCity() != undefined && session.userAddressCity() != "" && session.userAddressCity() != null) {
                    addressModel.city(session.userAddressCity());
                    streetLookupModel.city(session.userAddressCity());
                }
            }

            //  setAPNQuickSearchProperties(session.userAddressZip());
            APNSearchCounty(session.userCountyLabel());
            isSearchable(true);

            if (session.isGlobalLoginMessageEnabled() && !session.useOktaAuthentication() && localStorage['MessageDisplayed'] == 'false') {
                showPostLoginPopUp();
            }
        }



        function showPostLoginPopUp() {
            getGlobalLoginMessageText().done(function (data) {
                localStorage["MessageDisplayed"] = 'true';
                app.showMessage(data.replace('[servName]', session.brandServicesName()),
                    'Important Announcement',
                    ['Continue'],
                    true,
                {
                    "secondaryButtonClass": "btn-primary",
                    style: {
                        'max-width': "600px",
                        'max-height': "50vh!important"
                    }
                });

                
                return;
            })
            
        }
    

        function getGlobalLoginMessageText() {
            var message = https.get(config.getGlobalLoginMessageTextUrl);
            
            return message;
        }

        function setAPNQuickSearchProperties(zip) {
            //var url = config.getZipInfoUrl;
            //isSearchable(false);
            //APNSearchCounty('');
            //Q.when(https.get(url, {
            //    zipCode: zip,
            //    format: 'json'
            //}).then(function (data) {
            //    if (data != undefined && data != null && data.HasErrors == false) {
            //        if (data.CountyName != null && data.Fips != null) {
            //            if (session.allCountiesWithFips() != undefined && session.allCountiesWithFips() != null) {
            //                for (i = 0; i < session.allCountiesWithFips().length; i++) {
            //                    if (session.allCountiesWithFips()[i].value.toLowerCase() === data.Fips.toLowerCase()) {
            //                        APNSearchCounty(session.allCountiesWithFips()[i].label);
            //                        break;
            //                    }
            //                }
            //            }
            //        }
            //    }
            //    else
            //        session.zipCodeServiceDown();
            //}).always(function () {
            //    isSearchable(true);
            //}).fail(Failed));
        }

        function markCurrentUserAddress() {
            if (!session.isCustomer()) {
                if (currentUserFullAddress == null || currentUserFullAddress == "") {
                    Q.when(https.get(config.getCurrentUserFullAddress))
                        .then(function (data) {
                            if (data) {
                                currentUserFullAddress = data;
                                setmarkerOnAAddress(currentUserFullAddress);
                            }
                            else {
                                currentUserFullAddress = "";
                            }
                        })
                }
                else {
                    setmarkerOnAAddress(currentUserFullAddress);
                }
            }
            else {

                //session.fetchSalesExecutiveInfo().done(function (salesExecutive) {
                //    setmarkerOnAAddress(salesExecutive.Address() + ' ' + salesExecutive.City());
                //});

                setmarkerOnAAddress(session.customerAddress());

            }

        }

        function offsetMapCenter(x, y, newlocation) {

            var offset = new Microsoft.Maps.Point(x, y);
            var p = map.tryLocationToPixel(newlocation);
            if (p != null || p != undefined) {
                p.x += offset.x;
                p.y += offset.y;
                var newCenter = map.tryPixelToLocation(p);
                if (newCenter != null || newCenter != undefined) {
                    map.setView({
                        center: newCenter
                    });
                }

            }
        }



        function showAdvanceSearch() {
            if ($('#sideNavContainer').hasClass('show')) {
                //$('#sideNavContainer').removeClass('show');
                $('#sideNavContainer').collapse('hide');
            }
            vm.closeMultiplePropertyWarning();
            hideSideNav();
            isAdvanceSearchEnabled(true);
            isAdvanceSearchEnabled.valueHasMutated();
            isMinimizeAdvanceSearch(false);
            isPredictiveSearch(false);
            $('#pac-input').val('');
            expandAddressSearch();
            var testActivated = radiusSearch.activate();
            if (testActivated) radiusSearch.init('radiusRange', radiusChange);
        }

        function expandAddressSearch() {
            //$('#advanceSearchAddressPane').click();
            //            $('#collapseAddress').collapse('show');
            //$('#collapseAddress').collapse('show');
            //var addressSrch = $('#advanceSearchAddressPane');

            //if (activeAdvancedSearchUsed() == 'Address') {
            //    if ($('#advanceSearchAddressPane').hasClass('collapsed'))
            //        $('#advanceSearchAddressPane').click();
            //}

            //if (activeAdvancedSearchUsed() == 'Address') {
            //    if (!($('#collapseAddress').hasClass('show')))
            //        $('#collapseAddress').collapse('show');
            //}

            //var addressSrch = $('a[href="#Address"]');
            //if (activeAdvancedSearchUsed() == 'Address') {
            //    if ($('#Address').css('display') == 'none')
            //        addressSrch.click();
            //}

        }


        function closeAdvanceSearch() {
            isAdvanceSearchEnabled(false);
            resetAdvanceSearch();
            isPredictiveSearch(true);
            isStreetView(false);
        }

        function closeAdvanceSearchWithoutClearingData() {
            isAdvanceSearchEnabled(false);
            clearMarkers();
            isStreetView(false);
            propertyListing([]);
            isPredictiveSearch(true);
            isStreetView(false);
        }

        function resetAdvanceSearch() {
            //setAPNQuickSearchProperties(session.userAddressZip());
            apnModel.reset();
            APNFormatText("");
            ownerModel.reset();
            streetLookupModel.reset();
            clearMarkers();
            setPropertySearchControlValues(session.userAddressStateAbbr(), session.userAddressCity(), session.userAddressZip());
            if (!isAdvanceSearchEnabled())
                isStreetView(false);

            propertyListing([]);
        }

        function cleartMapResults() {
            vm.closeMultiplePropertyWarning();
            propertySearchCommon.searchDetails.searchContextChanged(Date());
            clearMarkers();
            propertyListing([]);
            isLoadCart(false);
            //map.setZoom(14);

            //map.setOptions(
            //{
            //    streetViewControl: false
            //});

        }


        function clearMarkers() {
            
            try {
                // Remove Push Pins
                if (map && map.entities) {
                    for (var i = map.entities.getLength() - 1; i >= 0; i--) {
                        var entity = map.entities.get(i);
                        map.entities.removeAt(i);
                        //if (entity instanceof Microsoft.Maps.Pushpin) {
                        //    map.entities.removeAt(i);
                        //}
                    }
                    //Remove Info Box
                    if (infoBoxRef && infoBoxRef.length > 0) {
                        for (var i = infoBoxRef.length - 1; i >= 0; i--) {
                            infoBoxRef[i].setMap(null);
                        }
                        infoBoxRef = [];
                    }
                }
            }
            catch (err) {
                infoBoxRef = [];
                //console.log('Error in clearMarkers: ' + err.message);
            }

        }

        function minimizeAdvanceSearch() {
            isMinimizeAdvanceSearch(true);
            isAdvanceSearchEnabled(false);
            isBasicSearchNoResult(false);
            vm.ownerModel.reset();
            vm.apnModel.reset();
            vm.streetLookupModel.reset();
            setPropertySearchControlValues(session.userAddressStateAbbr(), session.userAddressCity(), session.userAddressZip());
        }

        function searchProperty() {
            showAdvanceSearch();
            isBasicSearchNoResult(true);
        }

        function drawMap() {
            try {
                var navigationBarMode = Microsoft.Maps.NavigationBarMode;

                //console.log('mapTypeId set from drawMap');

                bingKey = session.bingMapAPIKey();

                //console.log('No Map Initialized.')
                //new MapWithSplashScreen(document.getElementById("myMap"), 

                if (session.isHighContrastEnabled()) {
                    map = new Microsoft.Maps.Map(document.getElementById('myMap'), {
                        credentials: bingKey,
                        center: new Microsoft.Maps.Location(currentLatitude(), currentLongitude()),
                        mapTypeId: Microsoft.Maps.MapTypeId.road,
                        navigationBarMode: navigationBarMode.compact,
                        zoom: 18,
                        minZoom: 4,
                        disableStreetside: true,
                        useInertia: false,
                        inertiaIntensity: 0,
                        enableClickableLogo: false,
                        fixedMapPosition: true,
                        disableTouchInput: true,
                        customMapStyle: midnightCommander
                        //
                        //disableScrollWheelZoom: true
                    });
                }
                else {
                    map = new Microsoft.Maps.Map(document.getElementById('myMap'), {
                        credentials: bingKey,
                        center: new Microsoft.Maps.Location(currentLatitude(), currentLongitude()),
                        mapTypeId: Microsoft.Maps.MapTypeId.aerial,
                        navigationBarMode: navigationBarMode.compact,
                        zoom: 18,
                        minZoom: 4,
                        disableStreetside: true,
                        useInertia: false,
                        inertiaIntensity: 0,
                        enableClickableLogo: false,
                        fixedMapPosition: true,
                        disableTouchInput: true,
                        customMapStyle: {}
                        //
                        //disableScrollWheelZoom: true
                    });
                }


                svLat(currentLatitude());
                svLng(currentLongitude());



                Microsoft.Maps.loadModule('Microsoft.Maps.AutoSuggest', function () {
                    var manager = new Microsoft.Maps.AutosuggestManager({
                        map: map,
                        countryCode: 'US'
                    });
                    manager.attachAutosuggest('#pac-input', '#searchBoxContainer', selectedSuggestion);
                });

                Microsoft.Maps.Events.addHandler(map, 'click', getDataByParcel);
                Microsoft.Maps.Events.addHandler(map, 'viewchangeend', viewChanged);
                isBingAvailable(true);
                if (searchManager == null || searchManager == undefined) {
                    //Create an instance of the search manager and perform the search.
                    Microsoft.Maps.loadModule(['Microsoft.Maps.SpatialDataService',
                        'Microsoft.Maps.Search'], function () {
                            searchManager = new Microsoft.Maps.Search.SearchManager(map);
                        });
                }



                //setTimeout(markCurrentUserAddress, 10);
                
                // ReportAllUSA parcel applied
                if (isParcelEnabled()) {
                    try {
                        var tileSource = new Microsoft.Maps.TileSource({
                            uriConstructor: REP.Layer.Bing.parcelsURIConstructor,
                            minZoom: 12,
                            maxZoom: 20
                        });
                        var tileLayer = new Microsoft.Maps.TileLayer({
                            mercator: tileSource, visible: true
                        });
                        map.layers.insert(tileLayer);
                        map.layers[0].setVisible(false);
                    } catch (err) {
                        alert.error('Error adding tile source: ' + err.message);
                    }
                }

                header.initAutoSuggest();
                //var vp_h = $(window).height();
                //var contentBodyHeight = vp_h - $('#header').height() - $('#footer').height();
                //$('#dvmapContainer').attr('style', 'height:' + contentBodyHeight + 'px');

                //    $('#dvmapContainer').attr('style', 'height:' + $('#SideMenu').height() + 'px !important');

                if (session.isSearchFromHeader()) {
                    session.isSearchFromHeader(false);
                    //$('#pac-input').val()
                    activeAdvancedSearchUsed('Address');
                    geocodeQuery(session.headerSearchText());
                }

                
                if (isLoadCart()) {
                    try {
                        //console.log(savedCart());
                       if (savedCart().APN.length > 0 && savedCart().FIPS.length > 0 ) {
                            onRecentSearchClick({
                                'FIPS': savedCart().FIPS,
                                'APN': savedCart().APN,
                                'State': savedCart().State
                            })
                       }
                       else if (savedCart().LatLongSearchQuery) {
                           geocodeQuery(savedCart().LatLongSearchQuery);
                       }
                        else {
                            app.showMessage('Cannot load Saved cart.', 'Error to load cart');
                            session.savedCart(undefined);
                           // isLoadCart(false);
                        }
                    }
                    catch(ex){
                        app.showMessage('Cannot load Saved cart.', 'Error to load cart');
                        session.savedCart(undefined);
                        isLoadCart(false);
                    }
                    
                }
                else {
                    markCurrentUserAddress();
                    
                }

            }
            catch (ex) {
                isBingAvailable(false);
                showAdvanceSearch();
                //router.navigate('#mapNotAvailable');
            }
        }

        function updateElementAttributes() {

            
            document.querySelector('.labelStyleSwitch').setAttribute('for', 'navbarLabelToggleInput');
            document.querySelector('.labelToggle_label').setAttribute('for', 'be2ToggleInput');
            document.getElementById('BirdseyeV2ExitButton').setAttribute('aria-label', 'birdsEyeExitButton')
            document.getElementById('RotateLeftButton').setAttribute('aria-label', 'rotate-leftbutton');
            document.getElementById('RotateButton').setAttribute('aria-label', 'rotate-button');
            document.getElementById('RotateRightButton').setAttribute('aria-label', 'rotate-rightbutton');
        }

        function viewChanged(e) {
            //console.log('current zoom is ' + map.getZoom());
            userZoomLevel(map.getZoom());

            $('#RadialMenu').hover(
                function () { $('.FindYourProperty').hide(); },
                function () { $('.FindYourProperty').show(); }
            );

            if (!$('#RadialMenu').hasClass('opened'))
            { $('.FindYourProperty').show(); }

            //console.log("$('#MapStyleSelector') " + $('#MapStyleSelector').length);



            //$('#MapStyleSelector').on('', function () { console.log('clicked'); });

        }


        function getFipsByZip(zip) {
            var url = config.getZipInfoUrl;

            if (session.userAddressZip() == zip && session.userZipInfo() != undefined) {
                return session.userZipInfo().Fips;
            }

            return Q.when(https.get(url, {
                zipCode: zip,
                format: 'json'
            })
                .then(function (data) {
                    if (data != undefined && data != null && data.HasErrors == false) {
                        if (data.Fips != null) {
                            return data.Fips;
                        }
                    }
                    else
                        session.zipCodeServiceDown();
                }));
        }

        function getDataByParcel(e) {
            isFindYourPropertyEnabled(false);
            //if (!isStreetView())
            //    return;

            if (e.targetType == "map") {
                var point = new Microsoft.Maps.Point(e.getX(), e.getY());
                var loc = e.target.tryPixelToLocation(point);
                isSearchByClick(true);
                searchByPoint(loc);
                return;

                //REP.Layer.Bing.IdentifyByPoint(map, loc, function (resp) {

                //    if (resp.results.length) {

                //        var respRow0 = resp.results[0];

                //        if (respRow0.parcel_id ==null)
                //            alert.warning('Valid property information could not be determined from the chosen parcel.  Try your search with more information in our "FIND YOUR PROPERTY Quick Search" or our "Advanced Search"');

                //        APNSearchOnClick({
                //                apnNumber: ko.observable(respRow0.parcel_id),
                //                zip: ko.observable(respRow0.census_zip),
                //                state: ko.observable(respRow0.state_abbr),
                //                county: ko.observable(respRow0.county_id)
                //        });

                //    //    if (currentAPNZip() != respRow0.census_zip) {
                //    //        getFipsByZip(respRow0.census_zip).done(function (res) {
                //    //            currentAPNZip(respRow0.census_zip);
                //    //            currentAPNFips(res);
                //    //            APNSearchOnClick({
                //    //                    apnNumber: ko.observable(respRow0.parcel_id),
                //    //                    zip: ko.observable(respRow0.census_zip),
                //    //                    state: ko.observable(respRow0.state_abbr),
                //    //                    county: ko.observable(currentAPNFips())
                //    //            });

                //    //        });
                //    //    }
                //    //    else {
                //    //        APNSearchOnClick({
                //    //                apnNumber: ko.observable(respRow0.parcel_id),
                //    //                zip: ko.observable(respRow0.census_zip),
                //    //                state: ko.observable(respRow0.state_abbr),
                //    //                county: ko.observable(currentAPNFips())
                //    //        });
                //    //}

                //}
                //}, function (errObj) {
                //    console.log('REP Overlays error: ' + errObj.message);
                //});
            }
        }

        function searchByPoint(location) {
            propertySearchCommon.searchDetails.searchContextChanged(Date());
            var reverseGeocodeRequestOptions = {
                location: location,
                callback: function (answer, userData) {
                    if (answer.address.countryRegion == "United States") {
                        getAPNAndSearch(location);
                    }
                    else {
                        handleNoResult();
                        //alert.warning('Not an valid USA address.', {timeout: 8000});
                    }

                },
                errorCallback: function (e) {
                    handleNoResult();
                }
            }
            searchManager.reverseGeocode(reverseGeocodeRequestOptions);

        }

        function getAPNAndSearch(location) {
            currentTargetLocation(location);
            REP.Layer.Bing.IdentifyByPoint(map, location, function (resp) {
                radiusSearch.searchRequest.currentRadius(radiusSearch.defaultRadius());
                if (resp.results.length) {

                    var respRow0 = resp.results[0];

                    //if (respRow0.parcel_id == null)
                    //    alert.warning('Valid property information could not be determined from the chosen parcel.  Try your search with more information in our "FIND YOUR PROPERTY Quick Search" or our "Advanced Search"');

                    location.latitude = respRow0.latitude;
                    location.longitude = respRow0.longitude;
                    currentTargetLocation(location);

                    APNSearchOnClick({
                        apnNumber: ko.observable(respRow0.parcel_id == undefined ? "xxxxxxxx" : respRow0.parcel_id),
                        zip: ko.observable(respRow0.census_zip),
                        state: ko.observable(respRow0.state_abbr),
                        county: ko.observable(respRow0.county_id),
                        latitude: ko.observable(respRow0.latitude),
                        longitude: ko.observable(respRow0.longitude),
                        landusecode: ko.observable(''),
                        radius: ko.observable(radiusSearch.searchRequest.currentRadius())
                    });
                }
                else {
                    //   handleNoResultLatLongSearchLocation();
                    APNSearchOnClick({
                        apnNumber: ko.observable('xxxxxxxx'),
                        zip: ko.observable('00000'),
                        state: ko.observable('-'),
                        county: ko.observable('-'),
                        latitude: ko.observable(location.latitude),
                        longitude: ko.observable(location.longitude),
                        landusecode: ko.observable(''),
                        radius: ko.observable(radiusSearch.searchRequest.currentRadius())
                    });
                }
            }, function (errObj) {
                //console.log('REP Overlays error: ' + errObj.message);
            });
        }

        function APNSearchOnClick(address) {
            vm.closeMultiplePropertyWarning();
            if (!session.llaAccepted()) {
                session.showLLAAgreement();
                return;
            }
            ///   cleartMapResults();
            isMarkable = true;

            isQuickSearchResult(false);
            isSearchable(false);


            apnSearch.addressSearch(APNsearchResult, address
                , isSearchable);
        }

        function doReverseGeocode(e) {
            if (activeInfobox && !activeInfobox.getVisible()) {
                activeInfobox = null;
                return;
            }
            if (!isStreetView())
                return;

            if (e.targetType == "map") {
                var point = new Microsoft.Maps.Point(e.getX(), e.getY());
                var loc = e.target.tryPixelToLocation(point);
                var reverseGeocodeRequestOptions = {
                    location: new Microsoft.Maps.Location(loc.latitude, loc.longitude),
                    callback: function (answer, userData) {
                        map.setView({
                            bounds: answer.bestView
                        });
                        map.entities.push(new Microsoft.Maps.Pushpin(reverseGeocodeRequestOptions.location));
                        doSearchByAddress(answer.address);
                        if (map) {
                            map.setView({
                                zoom: userZoomLevel()
                            });
                        }
                        //console.log(answer.address.formattedAddress);
                    }
                }


                searchManager.reverseGeocode(reverseGeocodeRequestOptions);

            }

            //doSearchByAddress(results[0].address_components);
        }

        function handleSearchTextEnter() {
            $('#pac-input').blur();


            setTimeout(searchButtonClick(), 500);
            //
        }

        function selectedSuggestion(result) {
            //handleSearchObject(result);
            //setTimeout(geocodeQuery($('#pac-input').val(),200));
            radiusSearch.refreshRangeSlider();
            propertySearchCommon.searchDetails.searchContextChanged(Date());


            //Remove previously selected suggestions from the map.
            isSearchInProgress(true);
            map.entities.clear();
            //console.log(result);
            // using geocode query to handle existing bug in Bing Maps. Refer Mail of 13 April 2017
            //  doSearchByAddress(result.address);
            if (result.matchCode == undefined || result.matchCode == 'Good' || result.matchCode == 'UpHierarchy') {
                $('#pac-input').val(result.address.formattedAddress);
                //handleSearchTextEnter();
                geocodeQuery(result.address.formattedAddress);
            }
            else
                handleNoResult();

        }

        function handleSearchObject(result) {

            //Remove previously selected suggestions from the map.
            isSearchInProgress(true);
            map.entities.clear();
            //console.log(result);

            // following code need to be removed. This is a stop gap to handle the Autosuggest select & Enter button issue
            if (isBasicSearchNoResult()) {
                isBasicSearchNoResult(false);
                minimizeAdvanceSearch();
            }

            //if (result.address.countryRegion != 'United States') {
            //    //            //alert.error('Not an Valid US address.');
            //                isSearchInProgress(false);
            //                handleNoResult();
            //                return;
            //}

            if (result.entityType == "Address") {
                //  doSearchByAddress(result.address);
                if (result.matchCode == undefined || result.matchCode == 'Good' || result.matchCode == 'UpHierarchy') {
                    doSearchByAddress(result.address);
                    //
                }
                else
                    handleNoResult();
            }

            else if (result.entityType == null && result.locations != null && result.matchCode == "Good") {

                isFindYourPropertyEnabled(false);
                isLatLongSearchRequest(true);
                latLongSearchLocation(result.location);
                return searchByPoint(result.location);

                //var reverseGeocodeRequestOptions = {
                //    location: result.locations,
                //    callback: function (answer, userData) {
                //        if (answer.address.countryRegion != "United States") {

                //        } else {
                //            handleNoResult();
                //        }
                //    }
                //};
                //searchManager.reverseGeocode(reverseGeocodeRequestOptions);


            }
            //result.entityType == "RoadBlock" || result.entityType == "AdminDivision1" || result.entityType == "AdminDivision2" || result.entityType == "PopulatedPlace"
            else if (result.entityType == "RoadIntersection") {
                isFindYourPropertyEnabled(false);

                map.setView({
                    center: result.location,
                    zoom: 17
                });

                map.entities.push(new Microsoft.Maps.Pushpin(result.location));
            }
            else if (result.entityType == "PopulatedPlace" || result.entityType == "Neighborhood" || result.entityType == "AdminDivision1" || result.entityType == "AdminDivision2" || result.entityType == "CountryRegion" || result.entityType == "Postcode1") {
                isFindYourPropertyEnabled(false);
                var geoDataRequestOptions = {
                    entityType: result.entityType
                };

                Microsoft.Maps.SpatialDataService.GeoDataAPIManager.getBoundary(
                    result.location,
                    geoDataRequestOptions,
                    map,
                    function (data) {
                        //Add the polygons to the map.
                        if (data.results && data.results.length > 0) {
                            //map.entities.push(data.results[0].Polygons);
                            for (var i = 0; i < data.results[0].Polygons.length; i++) {

                                data.results[0].Polygons[i].setOptions({
                                    fillColor: 'rgba(46, 111, 133, 0.2)',
                                    strokeColor: 'blue',
                                    strokeThickness: 2
                                });

                                //array.push(data.results[0].Polygons[0].geometry.bounds);

                            }
                            map.entities.push(data.results[0].Polygons[0]);
                        } else {
                            //Display a pushpin if a boundary isn't found.
                            var pin = new Microsoft.Maps.Pushpin(data.location);
                            map.entities.push(pin);
                        }

                        //map.setView({
                        //  center: result.location,
                        //          zoom: 13
                        //  });
                        map.setView({
                            bounds: result.bestView
                        });
                        //map.entities.push(new Microsoft.Maps.Pushpin(result.location));
                    });
            }
            else if (result.location != null) {
                isFindYourPropertyEnabled(false);

                map.setView({
                    center: result.location,
                    zoom: 17
                });

                map.entities.push(new Microsoft.Maps.Pushpin(result.location));
            }

            else {
                handleNoResult();
            }


        }

        function handleNoResultLatLongSearchLocation() {
            handleNoResult();
            setMapAndMarkPushPin(latLongSearchLocation(), 17);
        }

        function setMapAndMarkPushPin(targetLocation, targetZoom) {
            map.setView({
                center: targetLocation,
                zoom: targetZoom
            });
            map.entities.push(new Microsoft.Maps.Pushpin(targetLocation));
        }

        //function IsLocationInUSA(Targetlocation) {
        //    var reverseGeocodeRequestOptions = {
        //        location: Targetlocation,
        //        callback: function (answer, userData) {
        //            return answer.address.countryRegion != "United States";
        //        }
        //    }
        //    searchManager.reverseGeocode(reverseGeocodeRequestOptions);
        //}

        function setmarkerOnAAddress(address) {
            try {

                //console.log('Users Address ' + address);
                //while (map == null || map == undefined)
                //{
                //    console.log('map is not initialized.');
                //}
                var requestOptions = {
                    bounds: map.getBounds(),
                    where: address,
                    callback: function (answer, userData) {
                        $('#MapStyleSelector,.slot').on('touchstart', function () {
                            if ($('#RadialMenu').hasClass('opened')) {
                                $('.FindYourProperty').hide();
                            }
                            else {
                                $('.FindYourProperty').show();
                            }

                        });
                        if (map && map.entities) {
                            map.entities.push(new Microsoft.Maps.Pushpin(answer.results[0].location));
                            map.setView({
                                center: answer.results[0].location,
                            });
                            updateElementAttributes();
                            if ($(window).width() < 800) {
                                offsetMapCenter(0, -150, answer.results[0].location);
                            }
                            else {
                                offsetMapCenter(0, -100, answer.results[0].location);
                            }
                        }
                    }
                };

                if (searchManager == null || searchManager == undefined) {
                    //Create an instance of the search manager and perform the search.
                    Microsoft.Maps.loadModule('Microsoft.Maps.Search', function () {
                        searchManager = new Microsoft.Maps.Search.SearchManager(map);
                        searchManager.geocode(requestOptions);
                    });
                }
                else {
                    searchManager.geocode(requestOptions);
                }



            }
            catch (err) {
                //console.log('Error in setmarkerOnAAddress: ' + err.message);
            }
        }


        function geocodeQuery(query) {
            var searchString = $('#pac-input').val();
            if (searchString == undefined || searchString == "") {
                searchString = query;
            }
            additionalAddressLine("");

            if (searchString.indexOf(",") > 0) {
                additionalAddressLine(searchString.split(',')[0]);
//                console.log(additionalAddressLine());
            }

            //alert.info(searchString);

            var searchRequest = {
                where: searchString,
                callback: function (r) {
                    if (r && r.results && r.results.length > 0) {

                        if (r.results[0] && r.results[0].address && r.results[0].address.countryRegion) {
                            if (r.results[0].address.countryRegion != 'United States') {
                                isSearchInProgress(false);
                                handleNoResult();
                                //alert.warning('Not an Valid US address.');
                                return;
                            }
                            else {
                                //console.log('location found');
                                handleSearchObject(r.results[0]);
                            }
                        }
                        else {
                            handleSearchObject(r.results[0]);
                        }

                    }
                },
                errorCallback: function (e) {
                    //If there is an error, alert the user about it.
                    //commonDialogModel.show("Property search", "No property found.");
                    handleNoResult();
                }
            };

            if (searchManager == null || searchManager == undefined) {
                //Create an instance of the search manager and perform the search.
                Microsoft.Maps.loadModule('Microsoft.Maps.Search', function () {
                    searchManager = new Microsoft.Maps.Search.SearchManager(map);
                });
            }

            //Make the geocode request.
            searchManager.geocode(searchRequest);
        }





        function AfterLoad() {
            if ($('#hdnIsLogin').val() == "true") {
                $('#page-wrapper').attr('style', 'margin-left:220!important');
            }
          //  updateElementAttributes();
        }
        function setupMetisMenu() {
            //console.log('setupMetisMenu() called');
            //console.log($('.sidebar-collapse').length);





            //$('.advanceSearchSidePanel').slimScroll({
            //    width: '365px',
            //    height: '500px',
            //});
            $('.slimScrollDiv').attr('style', 'width:100%;');
            $('.slimScrollDiv').addClass('repositionSearch');

            $('#side-menu').metisMenu();

        };


        function customComparablesPopup() {
            vm.closeMultiplePropertyWarning();
            if (session.userId != null) {
                if (!session.llaAccepted()) {
                    session.showLLAAgreement();
                    return;
                }
                return getProfileCustomSettingsForUser(session.userId).done(function (data) {
                    if (data != null) {
                        if (data.AutoComparables == true) {
                            customCompSettingsModel.autoComparables(true)
                        }
                        else {
                            customCompSettingsModel.autoComparables(false)
                        }
                        // customCompSettingsModel.customComparables(data.AutoComparables)
                        customCompSettingsModel.numberofSales(data.NumberOfSales);
                        customCompSettingsModel.bedrooms(data.BedroomsRange);
                        customCompSettingsModel.bathrooms(data.BathroomsRange);
                        customCompSettingsModel.squareFootage(data.SquareFootageRange);
                        customCompSettingsModel.yearBuiltRangeFrom(moment(data.DateBuiltRangeBegin).format("YYYY"));
                        customCompSettingsModel.yearBuiltRangeTo(moment(data.DateBuiltRangeEnd).format("YYYY"));
                        customCompSettingsModel.ageofSales(data.AgeOfSaleInMonths);
                        customCompSettingsModel.saleAmountFrom('$' +
                            data.SaleAmountRangeBegin.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"));
                        customCompSettingsModel.saleAmountTo('$' +
                            data.SaleAmountRangeEnd.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"));
                        customCompSettingsModel.radius(data.Radius);
                        customCompSettingsModel.sameLegalTract(data.SameLegalTract);
                        customCompSettingsModel.lotSize(data.LotSize);
                    } else {
                        customCompSettingsModel.autoComparables(true);
                        customCompSettingsModel.numberofSales(20);
                        customCompSettingsModel.bedrooms(2);
                        customCompSettingsModel.bathrooms(2);
                        customCompSettingsModel.squareFootage(.25);
                        customCompSettingsModel.yearBuiltRangeFrom('1950');
                        customCompSettingsModel.yearBuiltRangeTo(moment().year().toString());
                        customCompSettingsModel.ageofSales(6);
                        customCompSettingsModel.saleAmountFrom('$0.00');
                        customCompSettingsModel.saleAmountTo('$0.00');
                        customCompSettingsModel.radius(5.0);
                        customCompSettingsModel.sameLegalTract(false);
                        customCompSettingsModel.lotSize(.25);
                        saveCompSettings();

                    }

                    $('#mdCustomComparables').modal('show');

                    setTimeout(function () {
                        $('#mdCustomComparables').on('hidden.bs.modal', function (e) {
                            $('#btnGetPropertyProfile').eq(0).focus();
                        });
                    }, 200);

                })
            }


        }
        function getProfileCustomSettingsForUser(userId) {
            var url = config.getProfileCustomSettingsForUserUrl;
            return https.get(url, {
                userId: userId,
                format: 'json'
            });
        }

        function toggleAddToCart(item, event) {
            if (item.AddedToCart() == false) {
                item.AddedToCart(true);
                item.index(purchaseableReportsShoppingCart().length)
                if (!hasPrice() && item.Credits() > 0)
                    item.UseCredits(true);
                purchaseableReportsShoppingCart().push(item);

            }
            else {
                item.AddedToCart(false);
                purchaseableReportsShoppingCart().splice(item.index(), 1);
                for (var i = 0; i < purchaseableReportsShoppingCart().length; i++) {
                    purchaseableReportsShoppingCart()[i].index(i);
                }
            }

            if (purchaseableReportsShoppingCart().length > 0) {
                shoppingCartLabel("Shopping Cart (" + purchaseableReportsShoppingCart().length + ")");
                shoppingCartEnable(true);
            }
            else {
                shoppingCartLabel("Shopping Cart");
                shoppingCartEnable(false);
            }
        }

        function saveCompSettings() {

            if (vm.customSettingsErrors().length > 0) {
                vm.customSettingsErrors.showAllMessages();
                return;
            }
            var fromDate = customCompSettingsModel.yearBuiltRangeFrom();
            var toDate = customCompSettingsModel.yearBuiltRangeTo();

            if (!validateDateRange(fromDate, toDate)) {
                return false;
            }

            var fromAmt = customCompSettingsModel.saleAmountFrom();
            var toAmt = customCompSettingsModel.saleAmountTo();
            var amtchk = validateAmount(fromAmt, toAmt);
            if (amtchk == true) {
                alert.error("Sale Amount From cannot be greater than Sale Amount To. ");
                customCompSettingsModel.saleAmountTo('');
                return false;
            }

            var settings = {
                "AutoComparables": customCompSettingsModel.autoComparables() == true ? true : false,
                "NumberOfSales": customCompSettingsModel.numberofSales,
                "BedroomsRange": customCompSettingsModel.bedrooms,
                "BathroomsRange": customCompSettingsModel.bathrooms,
                "SquareFootageRange": customCompSettingsModel.squareFootage,
                "DateBuiltRangeBegin": "01/01/" + customCompSettingsModel.yearBuiltRangeFrom(),
                "DateBuiltRangeEnd": "12/31/" + customCompSettingsModel.yearBuiltRangeTo(),
                "AgeOfSaleInMonths": customCompSettingsModel.ageofSales,
                "SaleAmountRangeBegin": Number(customCompSettingsModel.saleAmountFrom().replace(/[^0-9\.]+/g, "")),
                "SaleAmountRangeEnd": Number(customCompSettingsModel.saleAmountTo().replace(/[^0-9\.]+/g, "")),
                "Radius": customCompSettingsModel.radius,
                "SameLegalTract": customCompSettingsModel.sameLegalTract,
                "LotSize": customCompSettingsModel.lotSize

            };
            url = $.getBasePath() + "api/Property/SaveProfileCustomSettingsForUser?userId=" + session.userId;
            return https.post(url, settings)
                .then(function (data) {
                    if (data) {
                        if (data == true) {
                            alert.success("Custom Comparables Settings saved successfully.");
                        }
                        else {
                            alert.error("unable to save Custom Comparables Settings")
                        }

                    }

                })
                .fail(Failed);
        }
        function showShoppingCart() {
            //console.log('Before');
            //console.log('purchasableProdutsShoppingCartList: ' + purchasableProdutsShoppingCartList());
            //console.log('purchaseableReportsShoppingCart: ' + purchaseableReportsShoppingCart().length);
            //console.log('selectedProperty: ' + selectedProperty());
            purchasableProdutsShoppingCartList([]);
            purchasableProdutsShoppingCartList(purchaseableReportsShoppingCart());

            //purchasableProdutsSelectedProperty(selectedProperty());
            //router.navigate("#purchasableProducts");

            calculateTotal();
            if (Total() <= 0) {
                enablePayment(false);
                paymentMode("-1");
            }

            $('#mdProductServices').modal('show');
            session.isBusy(false);
        }
        function validateDateRange(fromDate, toDate) {
            var fromDate = moment(fromDate);
            var toDate = moment(toDate);
            var invalidDate = (fromDate > toDate);
            var currentDate = new Date();

            if (fromDate.year() > currentDate.getFullYear() || toDate.year() > currentDate.getFullYear()) {
                alert.error("Year Built cannot be greater than " + currentDate.getFullYear());
                return false;
            }

            if (fromDate.year() < 1910 || toDate.year() < 1910) {
                alert.error("Year Built cannot be less than 1910. ");
                return false;
            }

            if (invalidDate) {
                alert.error("Year Built Range From date  cannot be greater than Year Built Range To. ");
                customCompSettingsModel.yearBuiltRangeTo('');
                return false;
            }
            return true;
            //return date;

        };
        function validateAmount(saleAmountFrom, saleAmountTo) {
            var saleAmtFrom = saleAmountFrom.replace(/[^0-9\.]+/g, "");
            var saleAmtTo = saleAmountTo.replace(/[^0-9\.]+/g, "");
            var amount = ((saleAmtFrom - saleAmtTo) > 0);
            return amount;

        };

        function onRecentSearchClick(data) {
            $('#pac-input').val('');
            //geocodeQuery(data.Latitude + " , " + data.Longitude);

            try {
                searchResult(undefined);
                isMarkable = true;

                var apnModel = new model.ApnSearch();
                isSearchInProgress(true);
                isFindYourPropertyEnabled(false);

                apnModel.reset();
                apnModel.zip("");
                apnModel.county(data.FIPS);
                apnModel.apnNumber(data.APN);
                apnModel.state(data.State);

                apnSearch.addressSearch(searchResult, apnModel, isSearchable);
            }
            catch (err) {
                Failed(err);
            }




            //propertyProfileModel.availableReportTypes([]);
            //selectedProperty(data);
            ////searchResult(data);
            //propertyListing([]);
            //propertyListing.push(data);
            //isStreetView(true);
            //isFindYourPropertyEnabled(false);
            //isMarkable = true;
            //loadPropertyDetail(data);
            //setProfileButtonVisibility();
            //isLatLongAvailable(true);
            //isLatLongAvailable(false);

        }

        function onRecentSearchHeaderClick() {
            $this = $('#recentSearchListPanel');
            if (!$this.hasClass('panel-collapsed')) {
                $this.parents('.recentSearchBadge').find('.recent-search-body').slideUp();
                $this.addClass('panel-collapsed');
                $this.parents('.recentSearchBadge').find('i').removeClass('fa-angle-double-up').addClass('fa-angle-double-down');
            } else {
                $this.parents('.recentSearchBadge').find('.recent-search-body').slideDown();
                $this.removeClass('panel-collapsed');
                $this.parents('.recentSearchBadge').find('i').removeClass('fa-angle-double-down').addClass('fa-angle-double-up');
            }
        }


        function onMoreClick() {
            $this = $('#moreListPanel');
            if (!$this.hasClass('panel-collapsed')) {
                $this.parents('.recentSearchBadge').find('.recent-search-body').slideUp();
                $this.addClass('panel-collapsed');
                $this.parents('.recentSearchBadge').find('i').removeClass('fa-angle-double-up').addClass('fa-angle-double-down');
            } else {
                $this.parents('.recentSearchBadge').find('.recent-search-body').slideDown();
                $this.removeClass('panel-collapsed');
                $this.parents('.recentSearchBadge').find('i').removeClass('fa-angle-double-down').addClass('fa-angle-double-up');
            }
        }

        function onprofileListClick() {
            $this = $('#profileListPanel');
            if (!$this.hasClass('panel-collapsed')) {
                $this.parents('.recentSearchBadge').find('.recent-search-body').slideUp();
                $this.addClass('panel-collapsed');
                $this.parents('.recentSearchBadge').find('i').removeClass('fa-angle-double-up').addClass('fa-angle-double-down');
                mobileViewProfileLinksVisible(false);
            } else {
                mobileViewProfileLinksVisible(true);
                $this.parents('.recentSearchBadge').find('.recent-search-body').slideDown();
                $this.removeClass('panel-collapsed');
                $this.parents('.recentSearchBadge').find('i').removeClass('fa-angle-double-down').addClass('fa-angle-double-up');
            }
        }

        //PurchasableProductsPaymentProcess
        function reset() {
            paymentMode("1");
            paymentModeView('paymentProcess/_cardInfo_ShoppingCart.html');
            tax(0);
            subTotal(0);
            totalCredits(0);
            Total(0);
            subTotalDisplay('');
            totalDisplay('');
            taxDisplay('');
            taxRateDisplay('');
            canPurchaseWithWallet(true);
            enablePayment(true);
        }

        session.toggleSideNav.subscribe(
            function () {
                if (isAdvanceSearchEnabled()) {
                    closeAdvanceSearch();
                }
            });

        isParcelOn.subscribe(function (isOn) {
            if (isOn) {
                toggleParcelTitle(' Hide Parcel');
            }
            else {
                toggleParcelTitle(' Show Parcel');
            }
            if (map && map.layers && map.layers.length > 0)
                map.layers[0].setVisible(isOn);
        });

        isFindPropertyOn.subscribe(function (isOn) {
            if (isOn) {
                isFindYourPropertyEnabled(true);
                toggleFindPropertyTitle(' Hide Search Box');
            }
            else {
                isFindYourPropertyEnabled(false);
                toggleFindPropertyTitle(' Show Search Box');
            }
        })

        session.isHighContrastEnabled.subscribe(function (value) {
            isHighContrastOn(value);
        });

        isHighContrastOn.subscribe(function (isOn) {
            //console.log(' isHighContrastOn ' + isOn);
            //if (session.isHighContrastEnabled() == isOn)
            //    return;

            if (!isOn) {
                if (map) {
                    map.setOptions({ customMapStyle: {} });
                    map.setView({
                        mapTypeId: Microsoft.Maps.MapTypeId.aerial,
                    });
                }
            }
            else {
                if (map) {
                    map.setOptions({ customMapStyle: midnightCommander });
                    map.setView({
                        mapTypeId: Microsoft.Maps.MapTypeId.road,
                    });
                }
            }
        });

        isPredictiveInput.subscribe(function (focused) {
            if (focused) {
                hideSideNav();
            }
        });

        vm.isBasicSearchNoResult.subscribe(function (result) {
            //console.log('basic search result ' + result);
        });


        function hideSideNav() {
            if ($('.mini-navbar').is(":visible")) {
                //$('.navbar-minimalize')[0].click()
            }
        }



        paymentMode.subscribe(function (newValue) {
            // credit card
            if (enablePayment()) {
                if (newValue == 0) {
                    if (purchasableProdutsWalletBalance() < Total()) {
                        canPurchaseWithWallet(false);
                    }
                    else {
                        canPurchaseWithWallet(true);
                    }
                    paymentModeView('paymentProcess/_wallet.html');
                }
                else {
                    paymentModeView('paymentProcess/_cardInfo_ShoppingCart.html');
                    creditCardInformationModel.reset();
                }
            }
            else {
                paymentModeView('paymentProcess/_noPayment.html');
            }
        });

        //function activate() {
        //    calculateTotal();
        //	  creditCardInformationModel.reset();
        //    if (Total() <= 0) {
        //        enablePayment(false);
        //        paymentMode("-1");
        //    }
        //    return true;
        //};

        function showAddFundsWalletModal() {
            $('#mdAddFundsWalletModal').modal({
                backdrop: 'static',
                keyboard: false
            });
            //$('#mdProductServices').modal('hide');
            creditCardInformationModel.reset();
            amountToAdd(0.0);
            ko.validation.group(vm).showAllMessages(false);

            //$('#mdAddFundsWalletModal').modal('show');

            $("#mdAddFundsWalletModal").on("shown.bs.modal", function () {
                $('#mdAddFundsWalletModal .modalheight').scrollTop(0);
            }).modal('show');
        }

        function showCVCModal() {

            $('#mdWhatisCVC').modal('show');
            //$('#mdProductServices').modal('hide');

            //$("#mdWhatisCVC").on("shown.bs.modal", function () {
            //    $('#mdWhatisCVC .modal-body').scrollTop(0);
            //}).modal('show');
        }

        function HideCVCModal() {
            $('#mdWhatisCVC').modal('hide');
            //$('#mdProductServices').modal('show');
            session.isBusy(false);
        }

        function calculateTotal() {
            reset();
            purchasableProdutsWalletBalance(parseFloat(purchasableProdutsWalletBalance()).toFixed(2));
            if (purchasableProdutsShoppingCartList() != null && purchasableProdutsShoppingCartList().length > 0) {
                tax(parseFloat(purchasableProdutsTax()));
                for (var i = 0; i < purchasableProdutsShoppingCartList().length; i++) {
                    if (purchasableProdutsShoppingCartList()[i].UseCredits()) {
                        var existingCredits = totalCredits();
                        totalCredits(existingCredits + purchasableProdutsShoppingCartList()[i].Credits());
                    }
                    else {
                        hasPrice(true);
                        var existingSubTotal = subTotal();
                        var price = purchasableProdutsShoppingCartList()[i].Price();
                        if (price.indexOf('$') > -1)
                            price = price.replace('$', '');
                        subTotal(parseFloat((existingSubTotal + parseFloat(price))));
                    }
                }
                var creditsDisplay = '';
                if (subTotal() > 0) {
                    if (totalCredits() > 0) {
                        creditsDisplay = ' & ' + totalCredits() + ' Credits';
                    }
                    subTotalDisplay('$' + subTotal().toFixed(2) + creditsDisplay);
                    Total(subTotal() + (parseFloat((subTotal() * tax()))));
                    totalDisplay('$' + Total().toFixed(2) + creditsDisplay);
                    taxDisplay('$' + parseFloat((subTotal() * tax())).toFixed(2));
                }
                else if (subTotal() == 0 && hasPrice()) {
                    if (totalCredits() > 0) {
                        creditsDisplay = ' & ' + totalCredits() + ' Credits';
                    }
                    subTotalDisplay('$' + subTotal().toFixed(2) + creditsDisplay);
                    Total(subTotal() + (parseFloat((subTotal() * tax()))));
                    totalDisplay('$' + Total().toFixed(2) + creditsDisplay);
                    taxDisplay('$' + parseFloat((subTotal() * tax())).toFixed(2));
                }
                else {
                    if (totalCredits() > 0) {
                        creditsDisplay = totalCredits() + ' Credits';
                    }
                    totalDisplay(creditsDisplay);
                }
            }
            return true;
        }
        function canDeactivate() {
            //minimizeAdvanceSearch();
            window.history.pushState(null, "", window.location.href);
            window.onpopstate = function () {
                window.history.pushState(null, "", window.location.href);
                return false;
            };
            session.showTopSearchControl(true);
            //session.headerSearchText('');
            return true;
        };

        function goBack(complete) {
            router.navigateBack();
        }

        function HideAddFundsSuccessModal() {
            $('#addFundsSuccess').modal('hide');
            session.isBusy(false);
        }

        function placeOrderProducts() {

            if (vm.AmountToAddError != null && vm.AmountToAddError != 'undefined' && vm.AmountToAddError().length > 0) {
                vm.AmountToAddError.showAllMessages();
                $('#addFundsToWallet').modal('hide');
                return;
            }

            if (vm.creditCardValidationErrors != null && vm.creditCardValidationErrors != 'undefined' && vm.creditCardValidationErrors().length > 0) {
                vm.creditCardValidationErrors.showAllMessages();
                $('#addFundsToWallet').modal('hide');
                return;
            }
            //if (vm.errors != null && vm.errors != 'undefined' && vm.errors().length > 0) {
            //    vm.errors.showAllMessages();
            //    $('#addFundsToWallet').modal('hide');
            //    return;
            //}
            $('#addFundsToWallet').modal('show');
        }

        function AddFundsToWallet() {
            session.isBusy(true);
            //if (vm.errors != null && vm.errors != 'undefined' && vm.errors().length > 0) {
            //    vm.errors.showAllMessages();
            //    session.isBusy(false);
            //    return;
            //}
            if (vm.AmountToAddError != null && vm.AmountToAddError != 'undefined' && vm.AmountToAddError().length > 0) {
                vm.AmountToAddError.showAllMessages();
                session.isBusy(false);
                return;
            }

            if (vm.creditCardValidationErrors != null && vm.creditCardValidationErrors != 'undefined' && vm.creditCardValidationErrors().length > 0) {
                vm.creditCardValidationErrors.showAllMessages();
                session.isBusy(false);
                return;
            }

            $('#addFundsToWallet').modal('hide');

            var request = {
                "Products": null,
                "PropertyId": null,
                "UserId": session.userId,
                "OperationId": session.defaultOpertionId(),
                "BrandId": session.brandId,
                "CardHolderAddress":
                {
                    "Address1": creditCardInformationModel.billingAddress(),
                    "Address2": null,
                    "City": creditCardInformationModel.city(),
                    "State": creditCardInformationModel.state(),
                    "County": '',
                    "CountyIsOther": false,
                    "Zip": creditCardInformationModel.zip(),
                    "Zip4": null,
                    "Apn": null,
                    "Fips": null,
                    "Latitude": null,
                    "Longitude": null,
                },
                "PaymentTransaction":
                {
                    "PaymentType": 0,
                    "CardNumber": creditCardInformationModel.cardNumber,
                    "CardExpirationDate": creditCardInformationModel.expirationYear() + '-' + creditCardInformationModel.expirationMonth() + '-' + '01',
                    "CardCVS": creditCardInformationModel.verificationCodeCVC,
                    "NameOnCard": creditCardInformationModel.nameOnCard,
                    "ChargeAmount": amountToAdd,
                },

                "AmountToAdd": amountToAdd
            };

            var url = config.addFundsToWalletUrl;
            return https.post(url, request)
                .then(function (data) {
                    if (data.HasErrors == false) {

                        switch (data.Status) {
                            case 10://Success
                                if (data.Messages.length > 0) {

                                    var messageObj = data.Messages.filter(function (i) {
                                        return i.Level === 0;
                                    });
                                    var currWalletBalance = purchasableProdutsWalletBalance();
                                    var addedAmount = parseFloat(amountToAdd());
                                    var newWalletBalance = parseFloat(currWalletBalance) + addedAmount;
                                    purchasableProdutsWalletBalance(newWalletBalance.toFixed(2));
                                    walletBalance(newWalletBalance.toFixed(2));
                                    if (purchasableProdutsWalletBalance() > Total()) {
                                        canPurchaseWithWallet(true);
                                    }
                                    else {
                                        canPurchaseWithWallet(false);
                                    }
                                    $('#mdAddFundsWalletModal').modal('hide');
                                    $('#mdProductServices').modal('show');
                                    creditCardInformationModel.reset();
                                    amountToAdd(0);

                                    var messageText = "";
                                    $.each(messageObj, function (i, value) {
                                        messageText = messageText + " " + value.Message;
                                    });
                                    addFundsSuccessMessage(messageText.trim());
                                    //commonDialogModel.IsOrderDialog(false);
                                    //commonDialogModel.show("Add Funds To Wallet", messageText.trim());
                                    $('#addFundsSuccess').modal('show');
                                }
                                else {
                                    var currWalletBalance = purchasableProdutsWalletBalance();
                                    var newWalletBalance = parseFloat(currWalletBalance) + parseFloat(amountToAdd());
                                    purchasableProdutsWalletBalance(newWalletBalance.toFixed(2));
                                    if (purchasableProdutsWalletBalance() > Total()) {
                                        canPurchaseWithWallet(true);
                                    }
                                    else {
                                        canPurchaseWithWallet(false);
                                    }
                                    $('#mdAddFundsWalletModal').modal('hide');
                                    $('#mdProductServices').modal('show');
                                    creditCardInformationModel.reset();
                                    amountToAdd(0);

                                    addFundsSuccessMessage("Funds were added to your wallet successfully");
                                    //commonDialogModel.IsOrderDialog(false);
                                    //commonDialogModel.show("Add Funds To Wallet", "Funds were added to your wallet successfully");
                                    $('#addFundsSuccess').modal('show');
                                }
                                break;

                            case 20: alert.error(data.StatusMessage, "Add Funds To Wallet");//UnableToProcessPayment
                                alert.error("Unable To ProcessPayment : Error while adding funds to your wallet.", "Add Funds To Wallet");
                                break;
                            case 30: alert.error("No Available Products? Error while adding funds to your wallet.", "Add Funds To Wallet");//NoAvailableProducts
                                break;
                            case 0://UnKnown
                            case 100://Failed
                            default: alert.error("Error while adding funds to your wallet.", "Add Funds To Wallet");
                                break;
                        }
                    }
                    else {
                        if (data.Messages.length > 0) {
                            for (var i = 0; i < data.Messages.length; i++) {
                                alert.error('Error while adding funds to your wallet : ' + data.Messages[i].Message, "Add Funds To Wallet");
                            }
                        }
                        else {
                            alert.error("Error while adding funds to your wallet.", "Add Funds To Wallet");
                        }
                    }
                    creditCardInformationModel.reset();
                    amountToAdd(0);
                    session.isBusy(false);
                })
                .fail(function () {
                    alert.error('Error while adding funds to your wallet', "Add Funds To Wallet");
                    creditCardInformationModel.reset();
                    amountToAdd(0);
                    session.isBusy(false);
                });
        }

        function onCancelOrCloseAddFundsToWallet() {
            creditCardInformationModel.reset();
        }

        function PlaceOrder() {
            //if (paymentMode() == 1) {
            //    if (vm.creditCardValidationErrors != null && vm.creditCardValidationErrors != 'undefined' && vm.creditCardValidationErrors().length > 0) {
            //        vm.creditCardValidationErrors.showAllMessages();
            //        session.isBusy(false);
            //        return;
            //    }
            //}

            if (totalCredits() > purchasableProdutsCreditBalance()) {
                alert.error("You do not have enough credits for selected items.", "Payment Process");
                session.isBusy(false);
                return;
            }



            var amountToCharge = parseFloat(Total());
            //if (amountToCharge > 0) {
            //    var url = config.getPaymentSessionIdURL;
            //    https.get(url).then(function (data) {
            //        if (data.SessionId != "" && data.IsAuto == false) {
            //            Total(amountToCharge.toFixed(2))
            //            $('#pytSessionId').val(data.SessionId);
            //            $('#fmPayment').submit();
            //            return;
            //        }
            //    }).fail(Failed);
            //}

            session.isBusy(true);

            //console.log(purchasableProdutsShoppingCartList());

            

            var purchasableProdutsListJSONObject = ko.toJS(purchasableProdutsShoppingCartList());

            var request = null;
            if (!enablePayment()) {
                request = {
                    "Products": purchasableProdutsListJSONObject,
                    "PropertyId": selectedPropertyId(),
                    "UserId": session.userId,
                    "OperationId": session.defaultOpertionId(),
                    "BrandId": session.brandId,
                    "PropertyLatitude": selectedProperty().Latitude,
                    "PropertyLongitude": selectedProperty().Longitude,
                    "PaymentTransaction":
                    {
                        "PaymentType": 2,
                    }
                };
            }
            else {
                if (paymentMode() == 1) { //CreditCard
                    request = {
                        "Products": purchasableProdutsListJSONObject,
                        "PropertyId": selectedPropertyId(),
                        "UserId": session.userId,
                        "OperationId": session.defaultOpertionId(),
                        "BrandId": session.brandId,
                        "PropertyLatitude": selectedProperty().Latitude,
                        "PropertyLongitude": selectedProperty().Longitude,
                        "StateTax": tax(),
                        "OrderTax": parseFloat((subTotal() * tax())).toFixed(2),
                        "PaymentTransaction":
                        {
                            "PaymentType": 0
                        }
                    };
                }
            }
            //session.retainObj(true);
            var url = config.saveAndProcessPurchasableProductUrl;
            return https.post(url, request)
                .then(function (response) {
                    //console.log(response);
                    if (response.HasErrors == false) {
                        $('#mdProductServices').modal('hide');
                        if (response.FinalAmount > 0 && response.SessionId != "" && response.IsAuto == false) {
                            $('#paymentForm').prop("action", response.PaymentPostUrl);
                            $('#pytPageTitle').val("Product Order Payment");
                            $('#pytPageHeader').val("Product Order Payment");
                            $('#pytOrderNumber').val(response.OrderGuid);
                            $('#pytPassthroughData').val(response.OrderGuid);
                            $('#pytPaymentFormHeaderTextColor').val($('.btn-primary').css('color'));
                            $('#pytPaymentFormHeaderBackgroundColor').val($('.btn-primary').css('background-color'));
                            $('#pytLogoURL').val($('#dvBaseColor').css('background-image').split('"')[1]);
                            $('#pytSessionID').val(response.SessionId);
                            $('#pytCancelURL').val(response.CancelURL);
                            $('#pytSettlement').val(response.Settlement);
                            var amountToCharge = parseFloat(response.FinalAmount);
                            $('#pytAmount').val(amountToCharge.toFixed(2));

                            $('#pytCallBack').val(response.CallbackURL);
                            // $('#pytCallBack').val(response.DevCallbackURL);
                            $('#paymentForm').submit();
                        }
                        else {
                            if (response.CallbackURL !=null)
                                window.location = response.CallbackURL;
                            else {
                                alert.error('Error while getting report information', "Check Report");                    
                            }
                        }
                    }
                    else {
                        alert.error('Error while getting report information', "Check Report");
                    }
                    creditCardInformationModel.reset();
                    session.isBusy(false);
                })
                .fail(function () {
                    session.isBusy(false);
                    alert.error('Error while processing payment', "Payment Process");

                });

        }
        
        function CancelAddFunds() {
            $('#mdAddFundsWalletModal').modal('hide');
            //$('#mdProductServices').modal('show');
            onCancelOrCloseAddFundsToWallet();
            session.isBusy(false);
        }

        function Cancel() {
            reset();
            //router.navigateBack();
            $('#mdProductServices').modal('hide');
            onCancelOrCloseAddFundsToWallet();
        }

        function resetSessionVariables() {
            session.purchasableProdutsECommerceId(0);
            purchasableProdutsShoppingCartList([]);
            purchasableProdutsWalletBalance(0);
            purchasableProdutsCreditBalance(0);
            purchasableProdutsWalletEnabled(false);
            purchasableProdutsCreditsEnabled(false);
            purchasableProdutsSelectedProperty(undefined);
            purchasableProdutsTax(0.0);
        }

        function handleAfterPaymentResponse() {
            if (session.savedCart() != undefined) {
                savedCart(session.savedCart());
                session.savedCart(undefined);
                isLoadCart(true);
            }
        }

        

        vm.creditCardValidationErrors = ko.validation.group([vm.creditCardInformationModel]);
        vm.errors = ko.validation.group([vm]);
        vm.radiusSearch = radiusSearch;
        
        function radiusChange() {
            activeAdvancedSearchUsed('Radius Search');
            vm.closeMultiplePropertyWarning();
            vm.isBasicSearchNoResult(false);
            isQuickSearchNoResultError(false);
            var radiusLink = $('[href="#collapseRadius"]');

            clearMarkers();
            propertyListing([]);

            //if (radiusLink.hasClass('collapsed') == false) {
            //    radiusLink.click();
            //}

            APNSearchOnClick({
                apnNumber: ko.observable(radiusSearch.searchRequest.Apn()),
                zip: ko.observable(radiusSearch.searchRequest.Zip()),
                state: ko.observable(radiusSearch.searchRequest.State()),
                county: ko.observable(radiusSearch.searchRequest.County()),
                latitude: ko.observable(radiusSearch.searchRequest.latitude()),
                longitude: ko.observable(radiusSearch.searchRequest.longitude()),
                landusecode: ko.observable(''),
                radius: ko.observable(radiusSearch.searchRequest.currentRadius())
            });
        }
        return vm;
    });
