define('viewmodels/documentsForProperties/documentsForProperties',['durandal/app', 'knockout', 'services/logger', 'documentSearchModel', 'Q', 'plugins/http', 'config', 'metisMenu', 'jquery.slimscroll', 'global/session', 'plugins/router', 'alertNotification', 'commonDialogModel', 'koPaging', 'utlities'],
    function (app, ko, logger, model, Q, https, config, metisMenu, slimscroll, session, router, alert, commonDialog, koPaging, utlities) {

        var searchResult = ko.observable('');
        var apnModel = new model.ApnSearch();
        var addressModel = new model.AddressSerach();
        var documentNumberModel = new model.DocumentNumberSearch();

        var isSearchable = ko.observable(true);
        var stateList = ko.observableArray();

        var apnCountyList = ko.observableArray();
        var apnfips = ko.observableArray();
        var apnfipsNo = ko.observable('');

        var docCountyList = ko.observableArray();
        var docfips = ko.observableArray();
        var docfipsNo = ko.observable('');

        var propertyListing = ko.observableArray();
        var hasZipFocused = ko.observable(false);
        var documentDetailResponse = ko.observable(undefined);
        var reportsContainer = ko.observable('');
        var selectedProperty = new model.PropertyAddress();
        var selectedDocument = new model.DocumentInfo();
        var documentListing = ko.observableArray([]);
        var isShowNoDocumentsFound = ko.observable(false);
        var isShowNoResultsFound = ko.observable(false);
        var isShowDocumentsFound = ko.observable(false);
        var isShowAddressSearchResults = ko.observable(false);
        var isShowNoCreditsAvailable = ko.observable(false);
        var limitDocProp = ko.observable();
        hasZipFocused.subscribe(function (value) {
            //isSearchable(!value)
        });

        session.observeRouterActiveInstruction.subscribe(function (value) {
            clearSearch();
        });


        session.creditsDocProp.subscribe(function (newValue) {
         //   console.log("The person's new name is " + newValue);
            setActiveTab();
        });
        function setActiveTab() {

            if (session.creditsDocProp() > 0 && !($("#docSearchTabs > #docSearchMenu > a").hasClass("active"))) {
                $("#dvDocumentsSearch > #menu2").removeClass("show active");
            }

            if (session.creditsDocProp() <= 0) {
                $("#dvDocumentsSearch > #menu2").addClass("show active");
            }

            if ($("#docSearchTabs > #docSearchMenu > a").hasClass("active")) {
                $('#dvDocumentsSearch > #menu2').removeClass("show active").addClass("show active");
            }



        }

        var map, searchManager, poll;
        var suspendBusyCheck = false;
        var limitsbeforeDocOpen;
        var docClicked = false, pollStart = false;

        var vm = {
            suspendBusyCheck: suspendBusyCheck,
            canActivate: canActivate,
            activate: activate,
            deactivate: deactivate,
            searchResult: searchResult,
            compositionComplete: initMapModule,
            //bindingComplete: SetupDocumentGrid,
            addressModel: addressModel,
            apnModel: apnModel,
            documentNumberModel: documentNumberModel,
            searchDocumentByAdress: searchDocumentByAdress,
            searchDocumentByAPN: searchDocumentByAPN,
            clearSearch: clearSearch,
            clearSearchAddress: clearSearchAddress,
            clearSearchAPN: clearSearchAPN,
            clearSearchDocumentNumber: clearSearchDocumentNumber,
            searchDocumentByDocumentNumber: searchDocumentByDocumentNumber,
            apnfips: apnfips,
            apnfipsNo: apnfipsNo,
            docfips: docfips,
            docfipsNo: docfipsNo,
            GetStatesList: GetStatesList,
            stateList: stateList,
            apnCountyList: apnCountyList,
            docCountyList: docCountyList,
            isSearchable: isSearchable,
            hasZipFocused: hasZipFocused,
            getAddressString: getAddressString,
            selectedProperty: selectedProperty,
            documentListing: documentListing,
            resetSearchResults: resetSearchResults,
            selectedDocument: selectedDocument,
            isShowNoDocumentsFound: isShowNoDocumentsFound,
            isShowNoResultsFound: isShowNoResultsFound,
            isShowDocumentsFound: isShowDocumentsFound,
            isShowAddressSearchResults: isShowAddressSearchResults,
            isShowNoCreditsAvailable: isShowNoCreditsAvailable,
            clickDoc: clickDoc,
            getDocumentByRef: getDocumentByRef,
            session: session,
            docAddressSearch: docAddressSearch,
            openWindow: openWindow
        };

        vm.searchResult.subscribe(function (newValue) {
            console.log(newValue);
            if (newValue != undefined && newValue != '') {
                resetFlagValues();
                if (newValue.SearchParameters != undefined) {
                    console.log(newValue.SearchParameters.Address);
                    selectedProperty.update(newValue.SearchParameters);
                    if (selectedProperty.CompleteAddress().length > 1)
                        isShowAddressSearchResults(true);
                }

                documentListing([]);
                if (newValue.Properties != undefined && newValue.Properties.length > 0) {
                    documentListing(newValue.Properties[0].Documents);
                    if (documentListing().length == 0) {
                        isShowNoResultsFound(true);
                    }
                }
                else if (newValue.DocumentInformation != undefined && newValue.DocumentInformation != '') {
                    selectedDocument.update(newValue.DocumentInformation);
                    if (newValue.ResultStatus == 2) {
                        isShowNoDocumentsFound(true);
                        isShowDocumentsFound(false);
                    }
                    else if (newValue.ResultStatus == 1) {
                        isShowNoDocumentsFound(false);
                        isShowDocumentsFound(true);
                    }
                }
                else if (newValue != null && newValue.Properties != null && newValue.Properties.length == 0) {
                    if (newValue.StatusMessage == "No credits")
                        isShowNoCreditsAvailable(true);
                    else
                        isShowNoResultsFound(true);
                }
            }
            else {
                isShowNoResultsFound(true);
            }
        });

        vm.addressErrors = ko.validation.group([vm.addressModel]);
        vm.addressModel.zip.subscribe(function (zip) {
            if (zip != undefined && zip != '' && !isNaN(zip) && zip.length == 5) {
                var url = config.getZipInfoUrl;
                isSearchable(false);
                Q.when(https.get(url, {
                    zipCode: zip,
                    format: 'json'
                })
                    .then(function (data) {
                        if (data != undefined && data != null && data.HasErrors == false) {
                            if (data.CountyName != null && data.Fips != null) {
                                vm.addressModel.city(data.City);
                                vm.addressModel.state(data.StateAbbr);
                            }
                            else {
                                alert.error("Invalid Zip.");
                                addressModel.zip('');
                                addressModel.city('');
                                addressModel.state('');
                            }
                        }
                        else
                            session.zipCodeServiceDown();

                    }).always(function () {
                        isSearchable(true);
                    })

                    .fail(Failed));
            }
            else { }
        });

        vm.APNErrors = ko.validation.group([vm.apnModel]);
        vm.apnModel.zip.subscribe(function (zip) {
            if (zip != undefined && zip != '' && !isNaN(zip) && zip.length == 5) {
                apnfips([]);
                var url = config.getZipInfoUrl;
                isSearchable(false);
                Q.when(https.get(url, {
                    zipCode: zip,
                    format: 'json'
                })
                    .then(function (data) {
                        if (data != undefined && data != null && data.HasErrors == false) {
                            if (data.CountyName != null && data.Fips != null) {
                                apnfips(data);

                                if (apnfips().Fips != null) {
                                    apnfipsNo = apnfips().Fips;
                                }

                                apnModel.state(data.StateAbbr);
                                getApnModelCounties(data.StateAbbr);
                            }
                            else {
                                alert.error("Invalid Zip.");
                                apnModel.zip('');
                                apnModel.state('');
                                apnModel.county('');
                            }
                        }
                        else
                            session.zipCodeServiceDown();

                    }).always(function () {
                        isSearchable(true);
                    })
                    .fail(Failed));
            }
        });
        vm.apnModel.state.subscribe(function (state) {
            if (state != undefined && state != '') {
                getApnModelCounties(state);
            }
        });

        vm.DocumentNumberErrors = ko.validation.group([vm.documentNumberModel]);
        vm.documentNumberModel.state.subscribe(function (state) {
            if (state != undefined && state != '') {
                getDocModelCounties(state);
            }
        });

        return vm;

        function initMapModule() {
            session.validateInput();
            try {
                var bingKey = session.bingMapAPIKey();
                map = new Microsoft.Maps.Map('#docSearchMap', {
                    credentials: bingKey,
                    center: new Microsoft.Maps.Location(47.606209, -122.332071),
                    zoom: 12,

                });

                Microsoft.Maps.loadModule(['Microsoft.Maps.AutoSuggest', 'Microsoft.Maps.Search'], function () {
                    var manager = new Microsoft.Maps.AutosuggestManager({ map: map, countryCode: 'US' });
                    manager.attachAutosuggest('#textSearchBox', '#docSearchBoxContainer', selectedSuggestion);
                    searchManager = new Microsoft.Maps.Search.SearchManager(map);
                });
                session.isMapAvailable(true);

                $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
                    var target = e.target.attributes.href.value;
                    $(target + ' input')[0].focus();
                })
            }
            catch (ex) {
                session.isMapAvailable(false);
                console.log('map not available');
            }

            setActiveTab();
        }

        function selectedSuggestion(result) {
            console.log(result);
        }

        function resetFlagValues() {
            isShowNoResultsFound(false);
            isShowNoDocumentsFound(false);
            isShowDocumentsFound(false);
            isShowAddressSearchResults(false);
            isShowNoCreditsAvailable(false);

        }

        function docAddressSearch() {
            clearSearch();
            if (!session.isMapAvailable()) {
                searchDocumentByAdress();
                return;
            }
            map.entities.clear();
            var query = document.getElementById('textSearchBox').value;
            var searchRequest = {
                where: query,
                callback: function (r) {
                    if (r && r.results && r.results.length > 0) {

                        if (r.results[0] && r.results[0].address && r.results[0].address.countryRegion) {
                            if (r.results[0].address.countryRegion != 'United States') {
                                alert.warning('Not a Valid US address.');
                                return;
                            }
                            else {
                                addressModel = new model.AddressSerach();
                                var address = r.results[0].address;

                                addressModel.state(address.adminDistrict);
                                addressModel.city(address.locality);
                                addressModel.zip(address.postalCode);
                                addressModel.streetName(address.addressLine);

                                isSearchable(false);
                                searchResult(undefined);
                                addressDocumentSearches(searchResult, addressModel);
                            }
                        }
                        else {
                            alert.warning('Not a Valid US address.');
                        }


                    }
                },
                errorCallback: function (e) {
                    alert.warning('Not a Valid address.');
                }
            };
            //Make the geocode request.
            searchManager.geocode(searchRequest);
        }

        function GetStatesList() {
            var url = config.stateUrl;
            stateList([]);
            Q.when(https.get(url, {
                format: 'json'
            })
                .then(function (data) {
                    stateList(data);
                })
                .fail(Failed));
        }

        function Failed(error) {
            alert.error(error.statusText);
        }

        function getApnModelCounties(state) {
            var url = config.countyUrl;
            isSearchable(false);
            Q.when(https.get(url, {
                stateAbbr: state,
                format: 'json'
            })
                .then(function (data) {
                    apnCountyList(data);
                    if (apnfips().Fips != undefined && apnfips().Fips != '') {
                        apnModel.county(apnfips().Fips);
                    }
                }).always(function () {
                    isSearchable(true);
                })
                .fail(Failed)
            );
        }

        function getDocModelCounties(state) {
            var url = config.countyUrl;
            isSearchable(false);
            Q.when(https.get(url, {
                stateAbbr: state,
                format: 'json'
            })
                .then(function (data) {
                    docCountyList(data);
                    if (docfips().Fips != undefined && docfips().Fips != '') {
                        docModel.county(docfips().Fips);
                    }
                }).always(function () {
                    isSearchable(true);
                })
                .fail(Failed)
            );
        }

        function searchDocumentByAdress() {
            if (vm.addressErrors().length > 0) {
                vm.addressErrors.showAllMessages();
                return;
            }
            console.log('searchDocumentByAdress called');
            isSearchable(false);
            searchResult(undefined);
            addressDocumentSearches(searchResult, addressModel);

        }

        function searchDocumentByAPN() {
            if (vm.APNErrors().length > 0) {
                vm.APNErrors.showAllMessages();
                return;
            }

            isSearchable(false);
            //console.log('searchDocumentByAPN called');
            //console.log(apnModel);
            searchResult(undefined);
            apnDocumentSearch(searchResult, apnModel);
        }

        function clearSearch() {
            
            searchResult(undefined);
            selectedProperty.reset();
            documentListing([]);
            selectedDocument.reset();
            resetFlagValues();
        }

        function clearSearchAPN() {
            apnModel.reset();
            searchResult(undefined);
            selectedProperty.reset();
            documentListing([]);
            selectedDocument.reset();
            resetFlagValues();
        }

        function clearSearchAddress() {
            $('#textSearchBox').val('');
            addressModel.reset();
            searchResult(undefined);
            selectedProperty.reset();
            documentListing([]);
            selectedDocument.reset();
            resetFlagValues();

        }

        function clearSearchDocumentNumber() {
            documentNumberModel.reset();
            searchResult(undefined);
            selectedProperty.reset();
            documentListing([]);
            selectedDocument.reset();
            resetFlagValues();

        }

        function apnDocumentSearch(parentResultHolder, apnModel) {
            clearSearch();
            var url = config.getDocsByAPNUrl;

            return https.get(url, {
                apn: apnModel.apnNumber().trim(),
                fips: apnModel.county(),
                format: 'json',
                handler: session.activeRoutingContext().config.service
            }).done(function (data) {
                if (data.Properties != null)
                    parentResultHolder(data);
                else
                    alert.warning("Documents could not be retrieved for this APN.");
            }).always(function () {
                isSearchable(true);
                //getCombinedServiceCreditLimts();
            })
                .fail(function () {
                    alert.error('Internal Server Error.');
                });
        }

        function getUsageDocForProp() {
            var url = config.getUsageUrl;
            return https.get(url, {
                userId: session.userId,
                operationId: session.defaultOpertionId(),
                serviceId: '94',
                format: 'json'
            });
        }

        function getCombinedServiceCreditLimts() {
            //var url = config.getCombinedServiceCreditLimtsUrl;
            //https.get(url, {
            //    "ParentId": session.bkSlsParentId(),
            //    "Token": session.bkSlsToken()
            //}).then(function (result) {
            //    setDocumentCredits(result);
            //    window.clearInterval(poll);
            //    pollStart = false;
            //    $("#mdLoading").modal('hide');
            //});
        }

        function getUsageOnline() {
            var url = config.getUsageUrl;
            return https.get(url, {
                userId: session.userId,
                operationId: session.defaultOpertionId(),
                serviceId: session.activeRoutingContext().config.service,
                format: 'json'
            });
        }

        function getUsageForOnlineDoc() {
            var url = config.getUsageUrl;

            https.get(url, {
                userId: session.userId,
                operationId: session.defaultOpertionId(),
                serviceId: session.activeRoutingContext().config.service,
                format: 'json'
            }).then(function (result) {
                setDocumentCredits(result);
                $("#mdLoading").modal('hide');
            });
        }

        function setDocumentCredits(creditData) {
            creditData.forEach(function (data) {
                switch (data.ServiceId) {
                    case 89:
                        session.limitLpsDoc(data.UsageLimits);
                        session.creditsLpsDoc(data.Credits);
                        break;
                    case 92:
                        session.limitOnlineDoc(data.UsageLimits);
                        session.creditsOnlineDoc(data.Credits);
                        break;
                    case 94:
                        session.limitDocProp(data.UsageLimits);
                        session.creditsDocProp(data.Credits);
                        break;

                }
            });

        }

        function addressDocumentSearches(parentResultHolder, addressModel) {
            if (addressModel.streetNumber && addressModel.streetNumber.length > 0) {
                addressModel.streetNumber = addressModel.streetNumber.trim();
            }

            if (addressModel.streetName && addressModel.streetName.length > 0) {
                addressModel.streetName = addressModel.streetName.trim();
            }

            if (addressModel.unitNumber && addressModel.unitNumber.length > 0) {
                addressModel.unitNumber = addressModel.unitNumber.trim();
            }

            if (addressModel.city && addressModel.city.length > 0) {
                addressModel.city = addressModel.city.trim();
            }

            var url = config.getDocsByAddressUrl;
            var request = {
                address: (addressModel.streetName() + ' ' + addressModel.unitNumber()).trim(),
                city: addressModel.city,
                state: addressModel.state,
                zip: addressModel.zip,
                format: 'json'
            };

            https.get(url, request)
                .done(function (data) {
                    if (data.Properties != null)
                        parentResultHolder(data);
                    else
                        alert.warning("Documents could not be retrieved for this property.");
                }).always(function () {
                    isSearchable(true);
                    //getCombinedServiceCreditLimts()
                })
                .fail(function () {
                    alert.error('Internal Server Error.');
                });
        }

        function searchDocumentByDocumentNumber() {
            if (vm.DocumentNumberErrors().length > 0) {
                vm.DocumentNumberErrors.showAllMessages();
                return;
            }

            isSearchable(false);
            console.log('searchDocumentByDocumentNumber called');
            console.log(documentNumberModel);
            documentNumberDocSearch(searchResult, documentNumberModel);
        }

        function documentNumberDocSearch(parentResultHolder, documentNumberModel) {
            var svcToUse = (session.activeRoutingContext().config.service === 94 && session.creditsLpsDoc() > 0) ? 89 : session.activeRoutingContext().config.service;
            var url = config.documentNumberSearchUrl + "/" + svcToUse + "?userId=" + session.userId + "&operationId=1254";
            var DocumentInfo = {
                "Fips": documentNumberModel.county(),
                "DocumentNumber": documentNumberModel.documentnumber(),
                "RecordingDate": documentNumberModel.recordingdate(),
                "Book": documentNumberModel.book(),
                "Page": documentNumberModel.page()
            };
            return https.post(url, DocumentInfo).then(function (data) {
                //isShowNoDocumentsFound(false);
                if (data)
                    parentResultHolder(data);
                else
                    alert.warning("We were unable to find the document using your search parameters.");
            }).always(function () {
                isSearchable(true);
                //getCombinedServiceCreditLimts();
            })
                .fail(function () {
                    alert.error('Internal Server Error.');
                });
        }

        function canActivate() {
            if (!session.llaAccepted()) {
                session.showLLAAgreement();
                return false;
            }
            return true;
        }

        function activate() {
            resetFlagValues();
            console.log(isShowNoDocumentsFound());
            GetStatesList();
            window.addEventListener('focus', refreshPoll);
            // window.addEventListener("load", openWindow);
            window.suspendBusy = function () {
                if (vm.suspendBusyCheck === true) {
                    vm.suspendBusyCheck = false;
                    return true;
                }
                else {
                    return false;
                }

            }
            //SetupDocumentGrid();
        }

        // does the actual opening
        function openWindow(options, data, prePrcsdUrl) {

            var ctrl = options.currentTarget;
            event = event || window.event;

            var newTitle = data.DocumentNumber;

            // find the url and title to set
            var href = (prePrcsdUrl == true ? data.Url : data.Url + '&' + newTitle);
            // or if you work the title out some other way...
            // var newTitle = "Some constant string";

            // open the window
            var newWin = window.open(href, "_blank");
            if (newWin) {
                // add a load listener to the window so that the title gets changed on page load
                try {
                    newWin.addEventListener("load", setTimeout(function () {
                        newWin.document.title = newTitle;
                    }, 500));
                }
                catch (loadErr) { }
            } else {
                var warningMessageIfPopupBlocked = "Please disable your popup blocker and try again.";
                alert.warning(warningMessageIfPopupBlocked);
            }
            // stop the default `a` link or you will get 2 new windows!
            options.returnValue = false;
            return false;
        }

        function refreshPoll() {
            if (docClicked === true) {
                vm.suspendBusyCheck = true;
                limitsbeforeDocOpen = session.creditsOnlineDoc();
                startPoll();
            }
            docClicked = false;
        }

        function deactivate() {
            clearSearch();
        }

        function getDocumentByRef(refData, options) {
            limitsbeforeDocOpen = session.creditsOnlineDoc();
            $("#mdLoading").modal('show');
            Q.when(https.get(refData.Url).then(function (data) {
                refData.Url = '/api/PropertyDocument/' + data + '/' + refData.DocumentNumber.replace(':', ' ').replace('/', ' ');
                //getCombinedServiceCreditLimts();
                openWindow(options, refData, true);
            }).fail(Failed));
            return false;
        }

        function clickDoc(refData, options) {
            if (refData != null && refData.Url != null && refData.Url.indexOf("handler=lps") >= 0) {
                var refDataClone = {
                    DocumentNumber: refData.DocumentNumber,
                    Url: refData.Url
                };
                getDocumentByRef(refDataClone, options);
                return false;
            }
            else {
                docClicked = true;
                limitsbeforeDocOpen = session.creditsOnlineDoc();
                $("#mdLoading").modal('show');
                openWindow(options, refData, true);
                startPoll();
                return false;
            }
        }

        function startPoll() {
            if (!pollStart) {
                poll = window.setInterval(getCombinedServiceCreditLimts, 6000);
                pollStart = true;
            }
        }

        function resetSearchResults(selectedItem, event) {
            addressModel.reset();
            apnModel.reset();
            documentNumberModel.reset();
            searchResult(undefined);
            selectedProperty.reset();
            documentListing([]);
            selectedDocument.reset();
            resetFlagValues();
        }

        function SetupDocumentGrid() {
            $('.advanceSearchDocSidePanel').slimScroll({
                width: '100%',
                height: '300px'
            });
        }

        function getAddressString(SearchParameters) {
            var addressString = '';
            if (SearchParameters) {
                if (SearchParameters.Address.length > 0)
                    addressString = SearchParameters.Address + ' ';

                if (SearchParameters.UnitType.length > 0)
                    addressString = addressString + SearchParameters.UnitType + ' ';

                if (SearchParameters.UnitNumber.length > 0)
                    addressString = addressString + SearchParameters.UnitNumber + ' ';

                if (SearchParameters.City.length > 0)
                    addressString = addressString + SearchParameters.City + ', ';

                if (SearchParameters.State.length > 0)
                    addressString = addressString + SearchParameters.State + ' ';

                if (SearchParameters.Zip.length > 0)
                    addressString = addressString + SearchParameters.Zip;

                if (SearchParameters.Zip4.length > 0) {
                    addressString = addressString + '-' + SearchParameters.ZIP4;
                }
            }

            return addressString;
        }

    });
